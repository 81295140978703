/**
 * Searches with the searched key and adds the value to the array if exists,
 * then searches at the value for the same key and if exists, it adds the object to the array
 * Doing the same until key is not found in a value
 * At the end it returns this array
 */
export function getRecursivePropertiesByKey<T extends Object, Y extends keyof T>(object: T, key: Y): T[Y][] {
  const result: T[Y][] = [];
  const iterate = (object: T[Y]) => {
    if (!object) {
      return;
    }
    result.push(object);

    if (!(key in object)) {
      return;
    }

    // @ts-ignore
    iterate(object[key]);
  };
  iterate(object?.[key]);
  return result;
}
