module.exports = {
  /**
   * @param {Array<(...args: []) => boolean>} functionList - An array of functions that return a boolean value.
   * @param {...any} params - Optional parameters to pass to the functions.
   * @returns {boolean} Returns true if at least one function returns a truthy value, otherwise false.
   */
  isAtLeastOneFunctionTruthy: (
    functionList,
    ...params
  ) => {
    return functionList.reduce(
      (prev, curr) => {
        return curr(...params) || prev;
      }, false);
  },
  /**
   * Check if a string starts with any element from an array of strings.
   * @param {string} needle - The string to check.
   * @param {string[]} haystack - The array of strings to compare against.
   * @returns {boolean} - Returns true if the needle starts with any element in the haystack, false otherwise.
  */
  startsWithOneElementFromArray: (needle, haystack) => {
    return haystack.reduce((prev, curr) => needle.startsWith(curr) || prev, false);
  }
};
