import { sharedRef } from '@vue-storefront/core';
import { webSocketPort } from '~/config/shared';
import { WEBSOCKET_CONFIG, WEBSOCKET_EVENT } from '~/constants/webSocket';
import { useStock, useI18n } from '~/composables';
import { IS_DEV } from '~/constants/env';
import { isWebSocketEnabledForCountry } from '~/helpers/locales/isWebSocketEnabledForCountry';

export const useCartWebSocket = (setCart: Function) => {
  const { setStock } = useStock('cart');
  const { countryCode } = useI18n();

  const socket = sharedRef<WebSocket | null>(null, WEBSOCKET_EVENT.SOCKET);

  function openWebSocket(cartId: string) {
    if (!isWebSocketEnabledForCountry(countryCode.value) || !process.env.enableWebSocket) {
      return;
    }
    const webSocketProdHost = `${WEBSOCKET_CONFIG.WSS_PROTOCOL}${window.location.host}`;
    const host = IS_DEV ? WEBSOCKET_CONFIG.DEV_HOST : webSocketProdHost;
    socket.value = new WebSocket(`${host}:${webSocketPort}?${WEBSOCKET_CONFIG.CART_ID}=${cartId}`);

    socket.value.addEventListener('message', (message: MessageEvent) => {
      const parsedData = JSON.parse(message.data);
      const event = parsedData.event;
      const cart = parsedData?.data?.cart;
      const stock = parsedData.data?.results;

      // when order is created socket value should be reset
      if (!cart) {
        socket.value = null;
      }

      switch (event) {
        case WEBSOCKET_EVENT.CART_UPDATE:
          setCart(cart);
          break;
        case WEBSOCKET_EVENT.UPDATED_STOCK:
          setStock(stock);
          break;
      }
    });
  }

  return {
    openWebSocket,
    socket
  };
};
