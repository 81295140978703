import { countryCodes } from './enums/countryCodes';
import languageCodes from './enums/languageCodes';
import { currencyCodes } from './enums/currencyCodes';

export const countryConfigurations = [
  {
    countryCode: countryCodes.AT,
    label: 'Austria',
    languages: [languageCodes.de],
    currencyCode: currencyCodes.EUR,
    parcelPerformByLanguage: [{ parcelPerformId: '9d72683f81b6' }],
    languageAndCountryFallbacks: [
      { language: languageCodes.de, countryCode: countryCodes.DE }
    ],
    bloomreachKeyByLanguage: [{
      key: '612b67a0-2000-11ea-97de-02847d4e679f',
      lastSeenId: '64900dfb3b9da5e89987b5c6',
      similarProductsId: '64bf9740a5cf640e4e22934c',
      cartRecommendationId: '64e32cf7f8adfc0f377860bd'
    }]
  },
  {
    countryCode: countryCodes.BE,
    label: 'Belgium',
    languages: [languageCodes.fr, languageCodes.nl],
    currencyCode: currencyCodes.EUR,
    parcelPerformByLanguage: [
      { language: languageCodes.fr, parcelPerformId: 'f636aad955a6' },
      { language: languageCodes.nl, parcelPerformId: '33f8509e80e3' }
    ],
    languageAndCountryFallbacks: [
      { language: languageCodes.fr, countryCode: countryCodes.FR },
      { language: languageCodes.nl, countryCode: countryCodes.NL }
    ],
    bloomreachKeyByLanguage: [
      {
        language: languageCodes.fr,
        key: 'e7223ae4-2002-11ea-a509-02847d4e679f',
        lastSeenId: '649015f59045c5cb230e6f23',
        similarProductsId: '64bf9740cc5cba69fe08f384',
        cartRecommendationId: '64e32cf80b7c96cb7092297e'
      },
      {
        language: languageCodes.nl,
        key: '05b09f3c-2003-11ea-aeab-2266c08974f0',
        lastSeenId: '64901a57839194bcdadeeb5a',
        similarProductsId: '64bf9740a5cf640e4e229347',
        cartRecommendationId: '64e32cf838e88b37570152ff'
      }
    ]
  },
  {
    countryCode: countryCodes.BG,
    label: 'Bulgaria',
    languages: [languageCodes.bg],
    currencyCode: currencyCodes.BGN,
    parcelPerformByLanguage: [{ parcelPerformId: '' }],
    bloomreachKeyByLanguage: [{
      key: 'e4299884-dee3-11ed-8456-1ef90d2a052a',
      cartRecommendationId: '64e32cf792c178d9dac66c92'
    }]
  },
  {
    countryCode: countryCodes.CH,
    label: 'Switzerland',
    languages: [languageCodes.de, languageCodes.fr, languageCodes.it],
    currencyCode: currencyCodes.CHF,
    parcelPerformByLanguage: [
      { language: languageCodes.de, parcelPerformId: 'be0e7b604069' },
      { language: languageCodes.it, parcelPerformId: 'f24d4d81bbbf' },
      { language: languageCodes.fr, parcelPerformId: '6dc7badfbcb6' }
    ],
    languageAndCountryFallbacks: [
      { language: languageCodes.de, countryCode: countryCodes.DE },
      { language: languageCodes.fr, countryCode: countryCodes.FR },
      { language: languageCodes.it, countryCode: countryCodes.IT }
    ],
    bloomreachKeyByLanguage: [
      {
        language: languageCodes.de,
        key: '6a369368-2002-11ea-80f6-7641af141315',
        lastSeenId: '648c6ea451b4297bd7cc7f4c',
        similarProductsId: '64bf9740f149ca7dc77c66c0',
        cartRecommendationId: '64e32cf8a4a185daa6a2bd1f'
      },
      {
        language: languageCodes.fr,
        key: '8fa9ab12-2002-11ea-a298-2266c08974f0',
        lastSeenId: '648c7296c95fc18c33ae470d',
        similarProductsId: '64bf9740b58da410cde00ebc',
        cartRecommendationId: '64e32cf923fda1f5baa5be73'
      },
      {
        language: languageCodes.it,
        key: 'b7914f2c-2002-11ea-8f68-7abb5e11b5e7',
        lastSeenId: '649018024ebc4bcae90bc7a6',
        similarProductsId: '64bf97401b8c3078eb1848ef',
        cartRecommendationId: '64e32cf9c8e7c0ef68c26e5b'
      }
    ]
  },
  {
    countryCode: countryCodes.GB,
    label: 'United Kingdom',
    languages: [languageCodes.en],
    currencyCode: currencyCodes.GBP,
    parcelPerformByLanguage: [{ parcelPerformId: 'a988cef4236e' }],
    customDomainEnding: 'co.uk',
    customStoreEnding: 'co_uk',
    bloomreachKeyByLanguage: [{
      key: 'fea5ba72-1fff-11ea-a30f-5eb5c90936dd',
      lastSeenId: '649018f60d3049cf1ac86dae',
      similarProductsId: '64bf9740184ce0a42eca1029',
      cartRecommendationId: '64e32cf9d4379bccf815fd46'
    }]
  },
  {
    countryCode: countryCodes.CZ,
    label: 'Czech Republic',
    languages: [languageCodes.cs],
    currencyCode: currencyCodes.CZK,
    parcelPerformByLanguage: [{ parcelPerformId: '743dcc35ac1b' }],
    bloomreachKeyByLanguage: [{
      key: '96cb2d4a-2001-11ea-8519-7abb5e11b5e7',
      lastSeenId: '647f1d087d1bb51eb4f12097',
      similarProductsId: '649ed5703f207a1c5799de81',
      cartRecommendationId: '66ab6019099f5a95ad6fbdd4'
    }]
  },
  {
    countryCode: countryCodes.DE,
    label: 'Germany',
    languages: [languageCodes.de],
    currencyCode: currencyCodes.EUR,
    parcelPerformByLanguage: [{ parcelPerformId: '8baa8cc15002' }],
    bloomreachKeyByLanguage: [{
      key: 'bd34e8ba-1fff-11ea-bb3a-7abb5e11b5e7',
      lastSeenId: '648ac7f4d65f3b1ca3fe26b7',
      similarProductsId: '64b79d9edb5ed74be72e8301',
      cartRecommendationId: '64e32cfa766c89b2f3d9649e'
    }]
  },
  {
    countryCode: countryCodes.DK,
    label: 'Denmark',
    languages: [languageCodes.da],
    currencyCode: currencyCodes.DKK,
    parcelPerformByLanguage: [{ parcelPerformId: '5e0a79f27f03' }],
    bloomreachKeyByLanguage: [{
      key: '251a2a24-2002-11ea-af10-7abb5e11b5e7',
      lastSeenId: '64900f9ad30323172ee4d333',
      similarProductsId: '64bf97408683f21775c433c8',
      cartRecommendationId: '64e32cfa766c89b2f3d964b3'
    }]
  },
  {
    countryCode: countryCodes.ES,
    label: 'Spain',
    languages: [languageCodes.es],
    currencyCode: currencyCodes.EUR,
    parcelPerformByLanguage: [{ parcelPerformId: '28b4febf2a12' }],
    bloomreachKeyByLanguage: [{
      key: 'fcc47076-2000-11ea-bd3b-6ed5fa2c410f',
      lastSeenId: '64900f9a9574df67254f13b9',
      similarProductsId: '64bf9740ebce87f46e89176e',
      cartRecommendationId: '64e32cfa612f834f99309022'
    }]
  },
  {
    countryCode: countryCodes.FI,
    label: 'Finland',
    languages: [languageCodes.fi],
    currencyCode: currencyCodes.EUR,
    parcelPerformByLanguage: [{ parcelPerformId: '7eb702ad0ff9' }],
    bloomreachKeyByLanguage: [{
      key: '2de76f8c-2001-11ea-83b1-2266c08974f0',
      lastSeenId: '648c45d83a9aa5099074beaf',
      similarProductsId: '64bf97411b8c3078eb184903',
      cartRecommendationId: '64e32cfb3990b91fbc9a4fa9'
    }]
  },
  {
    countryCode: countryCodes.FR,
    label: 'France',
    languages: [languageCodes.fr],
    currencyCode: currencyCodes.EUR,
    parcelPerformByLanguage: [{ parcelPerformId: 'f1ee7aaa5380' }],
    bloomreachKeyByLanguage: [{
      key: '94310704-2000-11ea-a5e9-c266077c1d92',
      lastSeenId: '647f1d0874ea7fee01dcf6c6',
      similarProductsId: '6479a90493e156c61f150ca5',
      cartRecommendationId: '66ab6019dd1994ecbe571e74'
    }]
  },
  {
    countryCode: countryCodes.GR,
    label: 'Greece',
    languages: [languageCodes.el],
    currencyCode: currencyCodes.EUR,
    parcelPerformByLanguage: [{ parcelPerformId: '' }],
    bloomreachKeyByLanguage: [{
      key: '68e4c83c-dee4-11ed-8103-9a034ce46f20',
      cartRecommendationId: '64e32cfbdf937c7088cd89a8'
    }]
  },
  {
    countryCode: countryCodes.HR,
    label: 'Croatia',
    languages: [languageCodes.hr],
    currencyCode: currencyCodes.EUR,
    parcelPerformByLanguage: [{ parcelPerformId: '' }],
    bloomreachKeyByLanguage: [{
      key: '2d7c6cfa-dee4-11ed-ac21-a2bf0cb20f85',
      cartRecommendationId: '64e32cfca39be546f646a727'
    }]
  },
  {
    countryCode: countryCodes.HU,
    label: 'Hungary',
    languages: [languageCodes.hu],
    currencyCode: currencyCodes.HUF,
    parcelPerformByLanguage: [{ parcelPerformId: '32d384e27c8b' }],
    bloomreachKeyByLanguage: [{
      key: '0a9c0b96-548b-11ea-8f5f-6e4a0cd122c0',
      lastSeenId: '649169a8399161ac373df356',
      similarProductsId: '64bfb93ffe1b3db7c4e25e4c',
      cartRecommendationId: '64e611f441bc7753369e66fa'
    }]
  },
  {
    countryCode: countryCodes.IE,
    label: 'Ireland',
    languages: [languageCodes.en],
    currencyCode: currencyCodes.EUR,
    parcelPerformByLanguage: [{ parcelPerformId: '4b8f9479d6af' }],
    languageAndCountryFallbacks: [
      { language: languageCodes.en, countryCode: countryCodes.GB }
    ],
    bloomreachKeyByLanguage: [{
      key: 'bd3f348a-2001-11ea-8a48-02847d4e679f',
      lastSeenId: '649019c9996daf3d97f67f86',
      similarProductsId: '6697a384b2f51a3f5e4c0f12',
      cartRecommendationId: '64e32cfccf3e7e417ca5b77b'
    }]
  },
  {
    countryCode: countryCodes.IT,
    label: 'Italy',
    languages: [languageCodes.it],
    currencyCode: currencyCodes.EUR,
    parcelPerformByLanguage: [{ parcelPerformId: 'a5459118f459' }],
    bloomreachKeyByLanguage: [{
      key: 'b748420c-2000-11ea-8409-9ab905018a3b',
      lastSeenId: '648c45d8663885a5b336acc3',
      similarProductsId: '64bf974196864cfbe2baf92d',
      cartRecommendationId: '64e32cfcf274eafbed238420'
    }]
  },
  {
    countryCode: countryCodes.LT,
    label: 'Lithuania',
    languages: [languageCodes.lt],
    currencyCode: currencyCodes.EUR,
    parcelPerformByLanguage: [{ parcelPerformId: '' }],
    bloomreachKeyByLanguage: [{
      key: 'ac94db7e-7091-11ed-9d53-fe8ef836446c',
      cartRecommendationId: '64e32cfc5af55d63ddc324be'
    }]
  },
  {
    countryCode: countryCodes.NL,
    label: 'Netherlands',
    languages: [languageCodes.nl],
    currencyCode: currencyCodes.EUR,
    parcelPerformByLanguage: [{ parcelPerformId: '0cbd64eedd3d' }],
    bloomreachKeyByLanguage: [{
      key: 'f9bb98a4-2001-11ea-a6c5-6ed5fa2c410f',
      lastSeenId: '64916674f0176a3a48ae78c5',
      similarProductsId: '64bfbce0a4077c14ad96cf46',
      cartRecommendationId: '64e61a3b122d8f01054dd80b'
    }]
  },
  {
    countryCode: countryCodes.NO,
    label: 'Norway',
    languages: [languageCodes.nb],
    currencyCode: currencyCodes.NOK,
    parcelPerformByLanguage: [{ parcelPerformId: 'eb6d54426cce' }],
    bloomreachKeyByLanguage: [{
      key: '8f9d70b4-548b-11ea-8d4f-2a548b141b25',
      lastSeenId: '649170077b8dbc662d20f1e2',
      similarProductsId: '64bfc0ace19adef3341d4619',
      cartRecommendationId: '64e61a3afba11f502491ac60'
    }]
  },
  {
    countryCode: countryCodes.PL,
    label: 'Poland',
    languages: [languageCodes.pl],
    currencyCode: currencyCodes.PLN,
    parcelPerformByLanguage: [{ parcelPerformId: '39aadd4a0f9c' }],
    bloomreachKeyByLanguage: [{
      key: 'f01789a8-088f-11eb-bb5b-3290950b0a23',
      lastSeenId: '6474586a0056a5a725fd4d1a',
      similarProductsId: '6476f89ed00b0b187aaef650',
      cartRecommendationId: '64c241c0cb35677a61fb307b'
    }]
  },
  {
    countryCode: countryCodes.PT,
    label: 'Portugal',
    languages: [languageCodes.pt],
    currencyCode: currencyCodes.EUR,
    parcelPerformByLanguage: [{ parcelPerformId: '68139e1565ce' }],
    bloomreachKeyByLanguage: [{
      key: '4b042172-2002-11ea-af10-7abb5e11b5e7',
      lastSeenId: '6491739f331e39ee2915d6e5',
      similarProductsId: '64bfc22de19adef3341d462f',
      cartRecommendationId: '64e61a3a0a447c5128a98f57'
    }]
  },
  {
    countryCode: countryCodes.RO,
    label: 'Romania',
    languages: [languageCodes.ro],
    currencyCode: currencyCodes.RON,
    parcelPerformByLanguage: [{ parcelPerformId: '' }],
    bloomreachKeyByLanguage: [{
      key: '880ef3bc-5542-11ed-bfa2-8a678d008999',
      cartRecommendationId: '64e32cfd07e6a0864fc05afc'
    }]
  },
  {
    countryCode: countryCodes.SE,
    label: 'Sweden',
    languages: [languageCodes.sv],
    currencyCode: currencyCodes.SEK,
    parcelPerformByLanguage: [{ parcelPerformId: '86eeed6db148' }],
    bloomreachKeyByLanguage: [{
      key: '64054918-2001-11ea-a8b0-02847d4e679f',
      lastSeenId: '64917853aa2a1096f45c8d97',
      similarProductsId: '64bfc5e6dd801594b16e6777',
      cartRecommendationId: '64e32cfda4c946627e037d97'
    }]
  },
  {
    countryCode: countryCodes.SK,
    label: 'Slovakia',
    languages: [languageCodes.sk],
    currencyCode: currencyCodes.EUR,
    parcelPerformByLanguage: [{ parcelPerformId: '92123b4e844d' }],
    bloomreachKeyByLanguage: [{
      key: '0b2dfe52-79fa-11ec-884c-6a6262712ad7',
      lastSeenId: '64917853aa2a1096f45c8d97',
      similarProductsId: '64bfc5e6dd801594b16e6777',
      cartRecommendationId: '64e61a3ab83cc473efb55b30'
    }]
  },
  {
    countryCode: countryCodes.SI,
    label: 'Slovenia',
    languages: [languageCodes.sl],
    currencyCode: currencyCodes.EUR,
    parcelPerformByLanguage: [{ parcelPerformId: '' }],
    bloomreachKeyByLanguage: [{
      key: '2797cf26-5543-11ed-b479-f6a1cbed124c',
      cartRecommendationId: '64e32cfefb2f8065249cc0f4'
    }]
  }
];
