import { computed } from '@nuxtjs/composition-api';

interface ClickResult {
  newCurrentIndex: number;
  newPlacement: number;
}

interface UseCarouselParameters {
  thumbnailGap: number;
  thumbnailHeight: number;
  visibleItemsCount: number;
  sliderItemsLength: number;
}

export default ({
  thumbnailGap,
  thumbnailHeight,
  visibleItemsCount,
  sliderItemsLength
}: UseCarouselParameters) => {
  const spaceToFillPerItem = computed(() => thumbnailHeight + thumbnailGap);
  const maxPossibleIndex = computed(() => sliderItemsLength - visibleItemsCount);

  const calculateSpacing = (currentIndex: number): number => {
    const necessarySpacing = -currentIndex * spaceToFillPerItem.value;
    return necessarySpacing;
  };

  const handlePrevClickHelper = (currentIndex:number): Maybe<ClickResult> => {
    const hasPreviousItems = currentIndex > 0;
    if (!hasPreviousItems) {
      return;
    }
    const newCurrentIndex = Math.max(0, currentIndex - visibleItemsCount);
    const newPlacement = calculateSpacing(newCurrentIndex);
    return {
      newCurrentIndex,
      newPlacement
    };
  };

  const handleNextClickHelper = (currentIndex: number): Maybe<ClickResult> => {
    const lastShownItemIndex = currentIndex + visibleItemsCount;
    const hasMoreItems = lastShownItemIndex < sliderItemsLength;
    if (!hasMoreItems) {
      return;
    }
    const nextPageIndex = currentIndex + visibleItemsCount;
    const newCurrentIndex = Math.min(
      maxPossibleIndex.value,
      nextPageIndex
    );
    const newPlacement = calculateSpacing(newCurrentIndex);
    return {
      newCurrentIndex,
      newPlacement
    };
  };

  return {
    handleNextClickHelper,
    handlePrevClickHelper
  };
};
