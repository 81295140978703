import { useFacetFactory } from '@vue-storefront/core';
import type { Context, FacetSearchResult } from '@vue-storefront/core';
import type { FacetResultsData } from '@vsf-enterprise/commercetools';
import type { Category } from '@vsf-enterprise/commercetools-types';
import { useI18n, usePrefix } from '~/composables';
import { addEmptyChildren, reverseTree, removeEmptyCategories } from '~/helpers/category/categoriesTree';
import { CUSTOM_QUERIES } from '~/constants/customQueries';
/*
 * VSF native method
 * fetches categories for category page
 * copied into source code to add modifications to categories fetch (use custom queries) and to process the result
 */
const useFacetFactoryParams = {
  search: async (context: Context, params: FacetSearchResult<FacetResultsData>): Promise<FacetResultsData> => {
    const { languagePrefix } = usePrefix();
    const { languageAndCountry } = useI18n();
    const categoryResponse = await context.$ct.api.getCategory(
      { slug: params.input.categorySlug, languagePrefix },
      { categories: CUSTOM_QUERIES.CATEGORIES.sidebarNested }
    );
    const categoryRawResults: Category[] = categoryResponse?.data?.categories?.results || [];
    const currentCategoryResults: Category[] = removeEmptyCategories(categoryRawResults, languageAndCountry.value);

    let categories: Category[] = [];
    categories = addEmptyChildren(reverseTree(currentCategoryResults));

    if (categories[0]) {
      // head of tree
      categories[0].parent = null;
    }

    return {
      // Pass empty products, because now products are fetched from algolia
      results: [],
      categories,
      facets: [],
      rootCategory: categories[0],
      currentCategory: currentCategoryResults[0],
      total: 0
    };
  }
};

const useFacet = useFacetFactory<FacetResultsData>(useFacetFactoryParams);

export {
  useFacet,
  useFacetFactoryParams
};
