import { sharedRef } from '@vue-storefront/core';

export const usePromiseQueue = <T extends (...args: any[]) => Promise<any>>(func: T, cachingKey: string) => {
  const promise = sharedRef<Promise<ReturnType<T>|void>>(Promise.resolve(), cachingKey);
  const execute = async (...args: Parameters<T>): Promise<ReturnType<T>> => {
    await promise.value;
    promise.value = func(...args);
    return promise.value;
  };

  return {
    execute
  };
};
