import { useShippingProvider } from '@vsf-enterprise/commercetools';
import { ref, reactive, computed, onMounted } from '@nuxtjs/composition-api';
import { Logger, sharedRef, useVSFContext } from '@vue-storefront/core';
import type { ShippingMethod } from '@commercetools/platform-sdk';
import { CUSTOM_QUERIES } from '~/constants/customQueries';
import { useCartExtended, useCookies } from '~/composables';
import { ShippingMethodsResponse } from '~/types/checkout/ShippingMethod';

export default () => {
  const loading = ref(false);
  const shippingMethodsDataKey = 'shipping-methods-data-key';
  const { $ct, $cache } = useVSFContext();
  const { cart, setShippingDetails } = useCartExtended();
  const { country } = useCookies();
  const methodsWithoutId = sharedRef<ShippingMethodsResponse['shippingMethods']>([], shippingMethodsDataKey);
  const {
    state,
    error: errorShippingProvider,
    loading: loadingShippingProvider,
    save,
    load
  } = useShippingProvider();

  const error = reactive<{
    loadMethods: null | string | Error;
    selectShippingMethod: null | string | Error;
    loadMethodsWithoutId: null | string | Error;
  }>({
    loadMethods: null,
    selectShippingMethod: null,
    loadMethodsWithoutId: null
  });

  const loadProviderIfNeeded = async () => {
    if (cart.value && !state.value) {
      await load();
    }
  };

  onMounted(async () => {
    await loadProviderIfNeeded();
  });

  const loadMethodsWithoutId = async () => {
    if (methodsWithoutId.value?.length) return;

    error.loadMethodsWithoutId = null;
    try {
      let shippingMethods = await $cache.data.get(shippingMethodsDataKey);

      if (!shippingMethods) {
        shippingMethods = await $ct.api.getShippingMethodsWithoutId();
        await $cache.data.set(shippingMethodsDataKey, shippingMethods, [shippingMethodsDataKey]);
      }

      methodsWithoutId.value = shippingMethods?.data?.shippingMethods;
    } catch (err) {
      Logger.error('error.loadMethodsWithoutId = true ', String(err));
      error.loadMethodsWithoutId = err;
    }
  };

  const loadMethods = async (): Promise<ShippingMethod[] | null> => {
    try {
      error.loadMethods = null;
      const shippingMethodsResponse = await $ct.api.getShippingMethods(cart.value.id);
      return shippingMethodsResponse.data.shippingMethods;
    } catch (err) {
      if (err instanceof Error || typeof err === 'string') {
        Logger.error('selectDefaultShippingMethod: error.loadMethods is true ', String(err));
        error.loadMethods = err;
      }
      throw err;
    }
  };

  const selectShippingMethod = async (shippingMethod) => {
    try {
      if (loadingShippingProvider.value) {
        return;
      }
      error.selectShippingMethod = null;
      await save({
        shippingMethod,
        customQuery: CUSTOM_QUERIES.UPDATE_CART_CUSTOM
      });
      error.selectShippingMethod = errorShippingProvider.value?.save;
      if (error.selectShippingMethod) {
        Logger.error('selectShippingMethod save error: ' + String(error.selectShippingMethod));
      }
    } catch (err) {
      if (err instanceof Error || typeof err === 'string') {
        Logger.error('Shipping: selectDefaultShippingMethod: error.selectShippingMethod is true ', String(err));
        error.selectShippingMethod = err;
      }
      throw err;
    }
  };

  const selectDefaultShippingMethod = async () => {
    loading.value = true;
    if (!cart.value?.shippingAddress?.country) {
      await setShippingDetails({ country });
    }
    const shippingMethods = await loadMethods();
    if (!shippingMethods || error.loadMethods) {
      throw new Error('There is no shipping method to select');
    }
    await loadProviderIfNeeded();
    await selectShippingMethod(shippingMethods[0]);
    if (error.selectShippingMethod) {
      throw new Error('Error during shipping method selection: ' + error.selectShippingMethod);
    }
    loading.value = false;
  };

  const selectedShippingMethod = computed(
    () => state?.value?.response?.shippingMethod);
  const isShippingSelected = computed(() => !!selectedShippingMethod.value?.zoneRates);

  return {
    isShippingSelected,
    selectDefaultShippingMethod,
    loading,
    error,
    loadMethodsWithoutId,
    methodsWithoutId,
    selectedShippingMethod
  };
};
