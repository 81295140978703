import type { Ref } from '@nuxtjs/composition-api';
import { computed, ref, watch } from '@nuxtjs/composition-api';
import { sharedRef, useVSFContext } from '@vue-storefront/core';
import type { Address } from '@vsf-enterprise/commercetools-types';
import { ShippingMethodState } from '@vsf-enterprise/commercetools-types';
import { userShippingGetters, useShipping, useUser } from '@vsf-enterprise/commercetools';
import useShippingValidation from '~/composables/useShippingValidation';
import {
  useCartExtended,
  useCheckout,
  useCheckoutAddress,
  useCheckoutBilling,
  useCookies,
  useShippingMethods,
  useUserShippingExtended
} from '~/composables';
import useCheckoutClientType from '~/composables/useCheckoutClientType';
import callFunctionWithRetries from '~/helpers/retry/callFunctionWithRetries';
import { ShippingAddressType } from '~/types/checkout/ShippingAddressType';
import { shippingAddressTypeCookie } from '~/constants/cookies';
import { CUSTOM_QUERIES } from '~/constants/customQueries';
import registerAddressErrors from '~/helpers/Checkout/registerAddressErrors/registerAddressErrors';
import { ValidationProvider } from '~/types/vee-validate';

// shared state
const saveAddressCheckbox = ref(false);

const useCheckoutShipping = () => {
  const { validateAddress } = useShippingValidation();
  const { shipping: address, loading: loadingShipping, error: shippingError } = useShipping();
  const { defaultAddress, postEmail } = useCheckout();
  const { setClientTypeFromAddress, setClientType } = useCheckoutClientType('Shipping');
  const { $cookies } = useVSFContext();
  const { clientType: clientTypeBilling } = useCheckoutClientType('Billing');
  const shippingDetails = sharedRef<Partial<Address>>(address.value || defaultAddress, 'shippingDetails');
  const useCheckoutAddressInstance = useCheckoutAddress({
    addressType: 'Shipping',
    scrollToTopOfForm: '#ShippingForm',
    addressDetails: shippingDetails
  });
  const {
    displayNotification,
    resetNotifications,
    scrollToField,
    setScrollToField,
    removeUnnecessaryAddressProperties
  } = useCheckoutAddressInstance;
  const { cart, setShippingDetails } = useCartExtended();
  const {
    isShippingSelected,
    loading: loadingShippingMethods,
    error: errorShippingMethods
  } = useShippingMethods();
  const {
    isAuthenticated
  } = useUser();
  const {
    setDefaultAddress,
    shipping: userShipping,
    addAddress,
    updateAddress,
    error: userShippingError,
    currentCountryShippingAddresses
  } = useUserShippingExtended();
  const { billingDetails } = useCheckoutBilling();
  const { country } = useCookies();
  const { selectDefaultShippingMethod } = useShippingMethods();
  const oldShipping = ref<Partial<Address>>(defaultAddress);
  const anyShippingError = computed<Error | null | string>(() =>
    shippingError.value.load ||
    shippingError.value.save ||
    userShippingError.value.addAddress ||
    userShippingError.value.deleteAddress ||
    userShippingError.value.updateAddress ||
    userShippingError.value.load ||
    userShippingError.value.setDefaultAddress ||
    errorShippingMethods.selectShippingMethod ||
    errorShippingMethods.loadMethods
  );

  const canMoveForward = computed(
    () =>
      !loadingShipping.value &&
      !loadingShippingMethods.value &&
      !anyShippingError.value &&
      isShippingSelected
  );

  const handleShippingBeforeSubmit = async (
    isValid: Boolean,
    fields: Record<string, ValidationProvider> | undefined) => {
    resetNotifications();
    await setShippingFromBillingDetails();

    if (!isValid) {
      registerAddressErrors(fields);
    }
    await validateAddress(shippingDetails.value);
    postEmail(false);
  };

  const handleSaveShippingAddress = async () => {
    removeUnnecessaryAddressProperties();
    if (!shippingDetails.value.id) {
      const currentAddresses = currentCountryShippingAddresses.value;
      await addAddress({
        address: shippingDetails.value,
        customQuery: CUSTOM_QUERIES.UPDATE_MY_CUSTOMER_SHIPPING_ADDRESS
      });
      return currentCountryShippingAddresses.value.filter(address => !currentAddresses.includes(address))[0];
    }
    await updateAddress({
      address: shippingDetails.value,
      customQuery: CUSTOM_QUERIES.UPDATE_MY_CUSTOMER_SHIPPING_ADDRESS
    });
    return shippingDetails.value;
  };

  const handleShippingAfterSubmit = async () => {
    let savedAddress = null;
    if (isAuthenticated.value && saveAddressCheckbox.value) {
      savedAddress = await handleSaveShippingAddress();
    }
    if (savedAddress) {
      await setDefaultAddress({
        address: savedAddress,
        customQuery: CUSTOM_QUERIES.SET_MY_CUSTOMER_DEFAULT_SHIPPING_ADDRESS
      });
    } else if (shippingDetails.value.id) {
      await setDefaultAddress({
        address: shippingDetails.value,
        customQuery: CUSTOM_QUERIES.SET_MY_CUSTOMER_DEFAULT_SHIPPING_ADDRESS
      });
    }
    if (!canMoveForward.value) {
      displayNotification();
      throw new Error('Error');
    }
    if (!cart.value?.shippingInfo?.shippingMethod?.name ||
      cart.value?.shippingInfo?.shippingMethodState === ShippingMethodState.DoesNotMatchCart) {
      await callFunctionWithRetries(selectDefaultShippingMethod);
    }
  };

  const shippingAddressCookie = $cookies.get(shippingAddressTypeCookie);
  const hasSavedShippingAddress = computed(() => {
    if (!isAuthenticated.value || !userShipping.value) {
      return false;
    }
    return Boolean(currentCountryShippingAddresses.value?.length);
  });

  const handleSetCurrentAddress = async (address: Maybe<typeof shippingDetails.value>) => {
    await setClientTypeFromAddress(address);
    await setShippingAddressType(ShippingAddressType.SAVED_ADDRESS);
    shippingDetails.value = { ...(address || defaultAddress) };
  };

  const selectDefaultAddress = async () => {
    const defaultAddress = userShippingGetters.getAddresses(userShipping.value, { isDefault: true });
    if (
      defaultAddress &&
      defaultAddress.length &&
      defaultAddress[0] &&
      defaultAddress[0]?.country === country?.toUpperCase()
    ) {
      await handleSetCurrentAddress(defaultAddress[0]);
    }
  };

  const changeShippingDetails = (field: keyof typeof shippingDetails.value,
    value: ValueOf<typeof shippingDetails.value>) => {
    shippingDetails.value = {
      ...shippingDetails.value,
      [field]: value
    };
  };

  const shippingAddressType: Ref<typeof ShippingAddressType[keyof typeof ShippingAddressType]> =
    sharedRef(shippingAddressCookie || ShippingAddressType.SAME_AS_BILLING, 'shippingAddressType');

  const setShippingFromBillingDetails = async () => {
    if (shippingAddressType.value === ShippingAddressType.SAME_AS_BILLING) {
      if (!billingDetails.value) return;
      shippingDetails.value = { ...billingDetails.value };
      shippingDetails.value.id = undefined;
      await setClientType(clientTypeBilling.value);
    }
  };

  const setShippingAddressType = async (newValue: ShippingAddressType) => {
    shippingAddressType.value = newValue;
    $cookies.set(shippingAddressTypeCookie, newValue);
    if (shippingAddressType.value === ShippingAddressType.SAME_AS_BILLING) {
      oldShipping.value = { ...shippingDetails.value };
      await setShippingFromBillingDetails();
      return;
    }
    shippingDetails.value = oldShipping.value;
  };

  watch(shippingDetails, async (newShippingDetails) => {
    if (newShippingDetails.country.toLowerCase() !== country) {
      await setShippingDetails({ country });
    }
  }, { immediate: true });

  return {
    ...useCheckoutAddressInstance,
    handleShippingBeforeSubmit,
    handleShippingAfterSubmit,
    saveAddressCheckbox,
    hasSavedShippingAddress,
    shippingDetails,
    changeShippingDetails,
    handleSetCurrentAddress,
    anyShippingError,
    scrollToField,
    setScrollToField,
    setShippingFromBillingDetails,
    shippingAddressType,
    setShippingAddressType,
    selectDefaultAddress
  };
};

export default useCheckoutShipping;
