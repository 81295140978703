export type CategoryTree = {
  key: string,
  prefix: string,
  algoliaKey: string
}

function createTreeConfig<T extends Record<string, CategoryTree>>(x: T) { return x; }

export const CATEGORY_TREE = createTreeConfig({
  DEFAULT: { key: 'all-categories', prefix: '', algoliaKey: 'categories' },
  INDUSTRY: { key: 'industry-categories', prefix: 'industry', algoliaKey: 'industry-categories' },
  PRODUCTS: { key: 'products-categories', prefix: 'products', algoliaKey: 'products-categories' }
} as const);
