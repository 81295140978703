module.exports = {
  VAT_NUMBER_LABEL: 'vatNumber',
  ADDRESS_CUSTOM_FIELDS: 'addressCustomFields',
  ELIGIBLE_FOR_SELLING_CUSTOM_FIELD: 'eligibleForSelling',
  ALL_CATEGORIES: 'all-categories',
  FACET_SORTS: {
    LATEST: 'latest'
  },
  PRODUCT_VARIANT_ID: 1,
  VAT_VALIDATED_LABEL: 'vatValidated',
  EXCLUDE_PRODUCT_GRAPHQL_CUSTOM_FILTER: ' and masterData(current(slug(:CURRENT_LOCALE!=":EXCLUDED_SLUG")))',
  EXCLUDE_UNSELLABLE_PRODUCT_CUSTOM_FILTER: `masterData(current(masterVariant(attributes(name="eligibleForSelling"
  and value(:LANGUAGE_AND_COUNTRY="true")))))`,
  KEY_IN_GRAPHQL_CUSTOM_FILTER: ' and key in (:KEY_LIST)',
  KEY_IS_NOT_DEFINED: 'key is not defined',
  RELATED_PRODUCT_LIMIT: 8,
  VAT_RAW_RESPONSE: 'vatValidationRawResponse',
  STORE_CUSTOM_FIELDS: {
    EXTRA_GUARANTEE_ENABLED: 'sale_of_additional_guarantee_enabled',
    VAT_VALIDATION_ENABLED: 'vat_validation_enabled',
    CHANGE_TAX_RATE_ENABLED: 'change_tax_rate_enabled',
    AUTOCOMPLETE_ENABLED: 'google_autocomplete_enabled',
    TRUSTPILOT_IN_HEADER_ENABLED: 'trustpilot_in_header_enabled',
    CAMPAIGNS: 'ex_store_campaigns'
  },
  CATEGORY_CUSTOM_FIELDS: {
    IMG_URL: 'CategoryImageURL',
    HAS_PRODUCTS: 'hasProducts'
  },
  STORES_FIELD: 'stores',
  CAMPAIGN_INDICATOR: 'campaign:'
};
