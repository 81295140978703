import { productGetters } from '@vsf-enterprise/commercetools';
import { parseEnergyRating } from '../parseEnergyRating';
import type { ProductVariantExtended } from '~/types/product/ProductVariantExtended';
import type { ProductProcessedAttributes } from '~/types/product/attribute/ProductProcessedAttributes';
import type { ProductLocalisedAttributes } from '~/types/product/attribute/ProductLocalisedAttributes';
import getAttributeLocales from '~/helpers/product/getAttributeLocales';

const processLocalisedAttributes = (localisedAttributes: ProductLocalisedAttributes): ProductProcessedAttributes => {
  return {
    ...localisedAttributes,
    energyRating: parseEnergyRating(localisedAttributes.energyRating)
  };
};

export const extractLocalisedAttributes = (
  product: ProductVariantExtended,
  attributes: string[],
  languageAndCountry: string
): ProductProcessedAttributes => {
  return processLocalisedAttributes(
    getAttributeLocales(
      productGetters.getAttributes(product, attributes),
      languageAndCountry
    )
  );
};
