import { useStore } from '@vsf-enterprise/commercetools';
import { computed } from '@nuxtjs/composition-api';
import type { ComputedRef } from '@nuxtjs/composition-api';
import { useVSFContext, sharedRef } from '@vue-storefront/core';
import { useI18n, useUserOrder } from '~/composables';

import {
  COD_FEE_INFO_TITLE
} from '~/constants/checkout';
import customFieldsToBankInfo, { RawCustomFields } from '~/helpers/Checkout/customFieldsToBankInfo';
import { CUSTOM_QUERIES } from '~/constants/customQueries';
import { STORE_CUSTOM_FIELDS } from '~/constants/commerceTools';
import { Campaign } from '~/types/store/campaign';
import { buildCampaignLabel, extractActiveCampaigns } from '~/helpers/store/campaign';

export default function () {
  const useStoreInstance = useStore();
  const {
    load: oldLoad,
    response
  } = useStoreInstance;
  const { i18n } = useVSFContext();

  const {
    orderNumber
  } = useUserOrder();

  const loading = sharedRef<boolean>(true, 'store-loading');
  const customFieldsRaw = sharedRef<RawCustomFields>([], 'store-customFieldsRaw');

  const { countryLocalization } = useI18n();

  const codFeeTitle: ComputedRef<string> = computed(() => customFieldsRaw.value?.find(value =>
    value?.name === COD_FEE_INFO_TITLE)?.value);

  const findCustomFieldValue = (customField: ValueOf<typeof STORE_CUSTOM_FIELDS>) =>
    customFieldsRaw.value?.find(customFieldRaw =>
      customFieldRaw?.name === customField)?.value;

  const isCustomFieldTruthy = (customField: ValueOf<typeof STORE_CUSTOM_FIELDS>): boolean =>
    !!findCustomFieldValue(customField);

  const isExtraGuaranteeEnabled: ComputedRef<boolean> = computed(() =>
    isCustomFieldTruthy(STORE_CUSTOM_FIELDS.EXTRA_GUARANTEE_ENABLED)
  );
  const isAutocompleteEnabled: ComputedRef<boolean> = computed(() =>
    isCustomFieldTruthy(STORE_CUSTOM_FIELDS.AUTOCOMPLETE_ENABLED)
  );
  const isTrustpilotInHeaderEnabled: ComputedRef<boolean> = computed(() =>
    isCustomFieldTruthy(STORE_CUSTOM_FIELDS.TRUSTPILOT_IN_HEADER_ENABLED)
  );
  const isVatValidationEnabled: ComputedRef<boolean> = computed(() =>
    isCustomFieldTruthy(STORE_CUSTOM_FIELDS.VAT_VALIDATION_ENABLED)
  );
  const isChangeTaxRateEnabled: ComputedRef<boolean> = computed(() =>
    isCustomFieldTruthy(STORE_CUSTOM_FIELDS.CHANGE_TAX_RATE_ENABLED)
  );

  const bankInfo = computed(() => customFieldsToBankInfo(customFieldsRaw.value));

  const setCustomFieldsRaw = (value: RawCustomFields) => {
    customFieldsRaw.value = value ?? [];
  };

  const bankInfoComplete = computed(() => bankInfo.value && [
    { label: i18n.t('Account holder:'), value: bankInfo.value.bankAccountOwner },
    { label: i18n.t('Bank:'), value: bankInfo.value.bankInstitute },
    { label: i18n.t('Reference:'), value: orderNumber.value || i18n.t('Your order number') },
    { label: i18n.t('BIC/SWIFT:'), value: bankInfo.value.bankBic },
    { label: i18n.t('IBAN:'), value: bankInfo.value.bankIban }
  ]);

  const campaigns: ComputedRef<Campaign[]> = computed(() =>
    JSON.parse(findCustomFieldValue(STORE_CUSTOM_FIELDS.CAMPAIGNS) || '[]')
  );

  const activeCampaigns: ComputedRef<Campaign[]> = computed(() =>
    extractActiveCampaigns(campaigns.value)
  );
  const isLoaded = computed(() =>
    !!customFieldsRaw?.value?.length
  );

  const load = async () => {
    loading.value = true;
    await oldLoad({
      customQuery: {
        getStoresData: CUSTOM_QUERIES.STORES.getStoresData,
        metadata: `key="${countryLocalization.value.storeId}"`
      }
    });
    // TODO CT v2: oldLoad({ key: countryLocalization.value.storeId });
    const customFieldsRawResponse: typeof customFieldsRaw['value'] = response.value?.results?.find(result =>
      result && result.key === countryLocalization.value.storeId)?.custom?.customFieldsRaw;
    customFieldsRawResponse &&
      setCustomFieldsRaw(customFieldsRawResponse);
    loading.value = false;
  };

  const isCampaignLabel = (label: string): boolean => {
    return campaigns.value.some(campaign =>
      label === buildCampaignLabel(campaign.campaignName)
    );
  };

  const isActiveCampaignLabel = (label: string): boolean => {
    return activeCampaigns.value.some(campaign =>
      buildCampaignLabel(campaign.campaignName) === label
    );
  };

  return {
    ...useStoreInstance,
    load,
    codFeeTitle,
    loading: computed(() => useStoreInstance.loading.value || loading.value),
    bankInfo,
    isLoaded,
    bankInfoComplete,
    isAutocompleteEnabled,
    isTrustpilotInHeaderEnabled,
    isVatValidationEnabled,
    isChangeTaxRateEnabled,
    isExtraGuaranteeEnabled,
    isCampaignLabel,
    isActiveCampaignLabel,
    activeCampaigns
  };
}
