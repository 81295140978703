const PRODUCT_ATTRIBUTES = {
  BRAND: 'brand',
  BRUTTO_HEIGHT: 'bruttoHeight',
  BRUTTO_LENGTH: 'bruttoLength',
  BRUTTO_WEIGHT: 'bruttoWeight',
  BRUTTO_WIDTH: 'bruttoWidth',
  DELIVERY_PACKAGE: 'deliveryPackage',
  DESCRIPTION: 'description',
  ELIGIBLE_FOR_SELLING: 'eligibleForSelling',
  FAQ: 'faq',
  HIGHLIGHTS: 'highlights',
  IS_PACKAGE_TYPE_FREIGHT: 'isPackageTypeFreight',
  LOCALISABLE_TYPE_INDICATOR: 'LocalizableTextAttributeDefinitionType',
  MANUAL_URL: 'manualUrl',
  MODEL: 'model',
  NETTO_HEIGHT: 'nettoHeight',
  NETTO_LENGTH: 'nettoLength',
  NETTO_WEIGHT: 'nettoWeight',
  NETTO_WIDTH: 'nettoWidth',
  NUMBER_OF_360_IMAGES: 'numberOf360Images',
  PARENT_PRODUCTS_FOR_ACCESSORY: 'parentProductsForAccessory',
  RELATED_ACCESSORIES: 'relatedAccessories',
  RELEASE_DATE: 'releaseDate',
  STANDARD_PRICE: 'standardPrice',
  TAGS: 'tags',
  TECH_DETAILS: 'techdetails',
  VIDEO: 'video',
  ENERGY_RATING: 'energyRating'
};
const EXPONDO_SKU_PREFIX = 'EX';
const EXPONDO_SKU_LENGTH = 10;
const AWARE_PRODUCT_SKU_BEGINNING_CHARS = 'EX10';
const BWARE_PRODUCT_SKU_BEGINNING_CHARS = 'EX20';
const TEST_PRODUCT_SKU_BEGINNING_CHARS = 'EX5';

module.exports = {
  PRODUCT_ATTRIBUTES,
  AWARE_PRODUCT_SKU_BEGINNING_CHARS,
  BWARE_PRODUCT_SKU_BEGINNING_CHARS,
  TEST_PRODUCT_SKU_BEGINNING_CHARS,
  EXPONDO_SKU_PREFIX,
  EXPONDO_SKU_LENGTH
};
