import type { Category, RawCustomField } from '@vsf-enterprise/commercetools-types';
import { isRootCategory } from './isRootCategory';
import { CATEGORY_CUSTOM_FIELDS } from '~/constants/commerceTools.js';
import { StringBoolean } from '~/types/StringBoolean';

export const addEmptyChildren = (tree: Category[]): Category[] => tree.map(category => {
  if (category.children) {
    return { ...category, children: addEmptyChildren(category.children) };
  }
  category.children = [];

  return category;
});

export const reverseTree = (tree: Category[]): Category[] => {
  const result: Category[] = [];
  const traverseTree = (category: Maybe<Category>): Maybe<Category> => {
    if (!category) {
      return;
    }
    if (!category.parent) {
      result.push(category);
      return category;
    }

    const parentCategory = traverseTree(category.parent);
    parentCategory?.children?.forEach((child) => {
      if (child.slug === category.slug) {
        child.children = category.children;
      }
    });
    return category;
  };
  traverseTree(tree[0]);
  return result;
};

export const extractChildCategories = (tree: Category[] | Readonly<Category[]>): Maybe<Category[]> => {
  return tree[0]?.children;
};

export const removeEmptyCategories = (tree: Category[], activeLanguageAndCountry: string): Category[] => {
  const hasActiveProducts = (category: Category, activeLanguageAndCountry: string): boolean => {
    let hasActiveProductsFlag = false;
    if (isRootCategory(category)) {
      hasActiveProductsFlag = true;
      return hasActiveProductsFlag;
    }
    category.custom?.customFieldsRaw?.forEach((customField: RawCustomField) => {
      if (customField.name === CATEGORY_CUSTOM_FIELDS.HAS_PRODUCTS) {
        if (customField.value[activeLanguageAndCountry] === StringBoolean.true) {
          hasActiveProductsFlag = true;
        }
      }
    });
    return hasActiveProductsFlag;
  };

  const removeEmptyCategoriesRecursive = (categories: Category[], activeLanguageAndCountry: string): Category[] => {
    const filteredCategories: Category[] = [];

    categories.forEach((category: Category) => {
      if (hasActiveProducts(category, activeLanguageAndCountry)) {
        if (category.children) {
          category.children = removeEmptyCategoriesRecursive(category.children, activeLanguageAndCountry);
        }
        if (category.parent) {
          category.parent = removeEmptyCategoriesRecursive([category.parent], activeLanguageAndCountry)[0];
        }
        filteredCategories.push(category);
      }
    });

    return filteredCategories;
  };

  return removeEmptyCategoriesRecursive(tree, activeLanguageAndCountry);
};
