module.exports = {
  EXTRA_GUARANTEE_PRICE: 'EXTRA-GUARANTEE-PRICE',
  SKU_FOR_EXTRA_GUARANTEE: 'SKU-FOR-EXTRA-GUARANTEE',
  EXTRA_GUARANTEE_ATTRIBUTES: {
    EXCLUDED_BRANDS: 'extra-guarantee-excluded-brands',
    EXCLUDED_SKUS: 'extra-guarantee-excluded-skus',
    EXCLUDED_RELATIONS: 'extra-guarantee-excluded-relations',
    EXTRA_GUARANTEE_RATE: 'relative-price-rate'
  }
};
