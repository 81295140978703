import type { ProductVariant } from '@vsf-enterprise/commercetools-types';
import { Gtm } from '~/types/@nuxtjs/gtm';
import { AddToCartTags } from '~/plugins/integrations/tagManager/helpers/AddToCartTags';
import { prepareProductTags } from '~/plugins/integrations/tagManager/helpers/transformers';

export const triggerAddToCartTagsWrapper = ($gtm: Gtm, languageAndCountry: string, currency: string) => {
  return (
    product: ProductVariant, quantity: number, textDisplayed: string, triggeredIn: string) => {
    const productTags = prepareProductTags(product, { languageAndCountry, quantity, currency });
    const addToCartTags = new AddToCartTags(productTags, textDisplayed, triggeredIn);
    $gtm.push({ ecommerce: null });
    $gtm.push(addToCartTags);
  };
};
