const { getHostname } = require('../host/getHostname');

function getHost (request) {
  const host = request ? getHostname(request) : window.location.host;
  if (!host) {
    console.info(`Country is undefined, for host: ${host}`);
  }
  return host;
}

function isHostIp (host) {
  return host
    ?.split(':')
    .shift()
    .split('.')
    .filter(octet => parseInt(octet) >= 0 && parseInt(octet) <= 255)?.length === 4;
}

function getProtocol (request) {
  return (
    request ? request.headers['x-forwarded-proto'] || request.protocol || 'https' : window?.location?.protocol
  )
    ?.replace(':', '')
    ?.replace('//', '');
}

function getHttpInfo (req, country) {
  const PROTOCOL = getProtocol(req);

  const HOST = getHost(req, country);

  const BASE_URL = `${PROTOCOL}://${HOST}`;

  const IS_HOST_IP = isHostIp(HOST);

  return {
    PROTOCOL,
    HOST,
    IS_HOST_IP,
    BASE_URL
  };
}

module.exports = {
  getHttpInfo
};
