import { ALGOLIA_ROOT_LEVEL_QUERY_PARAMETERS } from '~/constants/algolia';
import { SearchQueryParameters } from '~/types/search/SearchQueryParameters';

export default function (params: SearchQueryParameters): SearchQueryParameters {
  return Object.keys(params)
    .filter(key => ALGOLIA_ROOT_LEVEL_QUERY_PARAMETERS.some(prefix => key.startsWith(prefix)))
    .reduce((accumulator: SearchQueryParameters, key) => {
      const value = params[key];
      if (value !== undefined) {
        accumulator[key] = value;
      }
      return accumulator;
    }, {});
}
