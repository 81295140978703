import Currency from './currency';
import languageCodes from '~/i18n/enums/languageCodes';
import { countryCodes } from '~/i18n';

export type LanguageIdentification =
  { language: ValueOf<typeof languageCodes>, countryCode : ValueOf<typeof countryCodes> };
export type ParcelPerformByLanguage = { language?: ValueOf<typeof languageCodes>, parcelPerformId: string };

export const BLOOMREACH_TYPE_NAME = {
  SIMILAR_PRODUCTS: 'similarProductsId',
  LAST_SEEN: 'lastSeenId',
  CART_RECOMMENDATION: 'cartRecommendationId'
} as const;
export type BloomreachTypeName = ValueOf<typeof BLOOMREACH_TYPE_NAME>;
export type BloomreachTypeId = string;
export type BloomreachKeyByLanguage = {
  key: string,
  language?: ValueOf<typeof languageCodes>,
  [BLOOMREACH_TYPE_NAME.LAST_SEEN]?: BloomreachTypeId,
  [BLOOMREACH_TYPE_NAME.SIMILAR_PRODUCTS]?: BloomreachTypeId,
  [BLOOMREACH_TYPE_NAME.CART_RECOMMENDATION]?: BloomreachTypeId
};
export type VueI18nNumberFormats = { [key: string]: { currency: Currency } };
export type VueI18nFormattedLocales = Array<{
  code: string,
  label: string,
  file: string,
  domain: string,
  country: string,
  algoliaIndexName: Maybe<string>
}>;
