import { PAGE_DELIVERY_KEYS } from '~/constants/cms';
import publishedCmsPages from '~/static/cms-schemas/published-pages.json';
import { PublishedPages } from '~/types/cms/PublishedPages';

export const isValidCmsPage = (slug: Maybe<string>, languageAndCountry: string): boolean => {
  const checkedSlug = slug || PAGE_DELIVERY_KEYS.HOME.SLUG;
  const checkedFileName = `${checkedSlug}-${languageAndCountry}`;
  const typedPublishedPages: PublishedPages = publishedCmsPages;
  return !!typedPublishedPages[checkedFileName];
};
