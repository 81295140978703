import {
  VSF_COUNTRY_COOKIE,
  VSF_LOCALE_COOKIE, VSF_STORE_COOKIE, VSF_CURRENCY_COOKIE
} from '@vue-storefront/core';
import { getPrefix } from '../../helpers/routes/prefixes';
import localizationDefinitions from '~/i18n/localizationDefinitions';
import { getHttpInfo } from '~/helpers/requestHandler';

export default async (context) => {
  const { $cookies, i18n, route, req } = context;
  const cookieNames = {
    country: VSF_COUNTRY_COOKIE,
    languageAndCountry: VSF_LOCALE_COOKIE,
    store: VSF_STORE_COOKIE,
    currency: VSF_CURRENCY_COOKIE
  };

  const { HOST } = getHttpInfo(req);
  const countryLocalization = localizationDefinitions.getByDomain(HOST, context);

  const cookieOptions = {
    path: '/',
    sameSite: 'lax',
    expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
  };

  $cookies.set(cookieNames.country, countryLocalization.countryCode, cookieOptions);

  /**
   * If the URL contains a language prefix, like `it` in `expondo.ch/it`, the language code (2 chars)
   * will be defined by the prefix. If not, the default language of the country is used.
   * In the example of Switzerland, the default language is `de` (German language).
  */
  const languagePrefix = getPrefix(route.path);
  const languageAndCountry = countryLocalization.getLanguageAndCountry(languagePrefix);

  $cookies.set(cookieNames.languageAndCountry, languageAndCountry, cookieOptions);

  await i18n.setLocale(languageAndCountry);

  const currentCookieStore = $cookies.get(cookieNames.store);
  const currentCookieCurrency = $cookies.get(cookieNames.currency);

  if ((currentCookieStore !== countryLocalization.storeId) || !currentCookieStore) {
    $cookies.set(cookieNames.store, countryLocalization.storeId, cookieOptions);
  }

  if ((currentCookieCurrency !== countryLocalization.currencyCode) || !currentCookieCurrency) {
    $cookies.set(cookieNames.currency, countryLocalization.currencyCode, cookieOptions);
  }
};
