
import {
  TRIGGERED_IN_LISTING,
  TRIGGERED_IN_HOME,
  TRIGGERED_IN_PDP_SIMILAR_PRODUCTS,
  TRIGGERED_IN_PDP_WARRANTY_SECTION,
  TRIGGERED_IN_CATEGORY,
  TRIGGERED_IN_PDP
} from '~/constants/googleTagManager';
import {
  useIsPage,
  useAlgolia
} from '~/composables';

type getAddToCartButtonLocationParams = {
  isMainProduct?: boolean;
  isWarrantySection?: boolean
};

type useRouteOfAddToCartReturn = {
  getAddToCartButtonLocation: (params: getAddToCartButtonLocationParams) => string;
};

export const useRouteOfAddToCart = (): useRouteOfAddToCartReturn => {
  const { isOnProductPage, isOnHomePage, isOnSearchPage } = useIsPage();
  const { isOnCategory } = useAlgolia();

  const getAddToCartButtonLocation = ({
    isMainProduct,
    isWarrantySection
  }: getAddToCartButtonLocationParams): string => {
    switch (true) {
      case isMainProduct:
        return TRIGGERED_IN_PDP;
      case isWarrantySection:
        return TRIGGERED_IN_PDP_WARRANTY_SECTION;
      case isOnSearchPage.value:
        return TRIGGERED_IN_LISTING;
      case isOnHomePage.value:
        return TRIGGERED_IN_HOME;
      case isOnProductPage.value:
        return TRIGGERED_IN_PDP_SIMILAR_PRODUCTS;
      case isOnCategory():
        return TRIGGERED_IN_CATEGORY;
      default:
        return '';
    }
  };

  return {
    getAddToCartButtonLocation
  };
};
