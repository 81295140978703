import { defineModule } from '~/helpers/vuex/defineModule/defineModule';
import {
  ClientEmailMutations
} from '~/types/vuex/ClientEmail';
import { MODULE_NAMES } from '~/types/vuex/Vuex';

export default function () {
  const store = defineModule<
    typeof MODULE_NAMES.CLIENT_EMAIL_MODULE_NAME,
    ClientEmailMutations,
    {},
    {}
  >(MODULE_NAMES.CLIENT_EMAIL_MODULE_NAME);

  const clientEmail = store.state.clientEmail;

  const setClientEmail = (email: string) => {
    store.mutations.setClientEmail(email);
  };

  return {
    clientEmail,
    ...store.getters,
    ...store.mutations,
    ...store.actions,
    setClientEmail
  };
}
