import type { ProductVariant } from '@vsf-enterprise/commercetools-types';

export const getRawAttributeAsArray = <T = string>(product: Maybe<ProductVariant>, attributeName: string): T[] => {
  const rawAttributeValue = product?.attributesRaw?.find(
    attribute => attribute.name === attributeName
  )?.value;

  if (!Array.isArray(rawAttributeValue)) {
    return [];
  }

  return rawAttributeValue;
};
