import { render, staticRenderFns } from "./RenderContent.vue?vue&type=template&id=0d37b31a&scoped=true&"
import script from "./RenderContent.vue?vue&type=script&lang=ts&"
export * from "./RenderContent.vue?vue&type=script&lang=ts&"
import style0 from "./RenderContent.vue?vue&type=style&index=0&id=0d37b31a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d37b31a",
  null
  
)

export default component.exports