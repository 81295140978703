
import { countryCodes } from '~/i18n/enums/countryCodes';

/**
 * IMPORTANT: If you add a country to the list below,
 * make sure to add the disclaimer translation for that country/language too!
 */
const PRICE_DISCLAIMER_COUNTRIES: Array<ValueOf<typeof countryCodes>> = [
  countryCodes.PL
];

export { PRICE_DISCLAIMER_COUNTRIES };
