import type { ProductVariantExtended } from '~/types/product/ProductVariantExtended';
import { getVatPercentage } from '~/helpers/product/getVatPercentage';
import type { CountryCode } from '~/i18n/enums/countryCodes';
import type { ProductVariantGql } from '~/types/product/ProductVariantGql';

interface QueryParams {
  isPublished?: Boolean;
  inStock?: Boolean;
}

const getTranslated = (rawAttribute, commerceToolsLocale: string) => {
  if (rawAttribute.attributeDefinition.type.name === 'ltext') {
    return rawAttribute.value[commerceToolsLocale];
  }

  if (rawAttribute.attributeDefinition.type.name === 'lenum') {
    return rawAttribute.value.label[commerceToolsLocale];
  }

  return rawAttribute.value;
};

const extendProducts = (
  products: readonly ProductVariantGql[],
  commerceToolsLocale: string,
  countryCode: CountryCode,
  params: QueryParams
): ProductVariantExtended[] => {
  return products
    ?.filter((product: any) => {
      return params.isPublished ? product.masterData?.published : true;
    }
    )
    .filter((product: any) =>
      params.inStock
        ? product.masterData?.current?.allVariants?.[0]?.availability?.noChannel?.isOnStock
        : true
    )
    .map((product: any) => {
      const current = product.masterData.current;

      return current.allVariants.map((variant) => ({
        ...variant,
        attributesRaw: variant.attributesRaw?.map((raw) => ({
          ...raw,
          _translated: getTranslated(raw, commerceToolsLocale)
        })) || [],
        _name: current.name,
        _slug: current.slug,
        _id: product.id,
        _key: product.key,
        _master: current.masterVariant.id === variant.id,
        _description: current.description,
        _meta: {
          description: current.metaDescription,
          keywords: current.metaKeywords,
          title: current.metaTitle
        },
        _categoriesRef: current.categoriesRef?.map((cr) => cr.id),
        _breadcrumbs: current.categories?.map((c) => {
          let res = [];

          try {
            for (const ancestor of c.ancestors) {
              res.push({
                name: ancestor.name,
                slug: ancestor.slug
              });
            }

            res.push({
              name: c.name,
              slug: c.slug
            });
          } catch (e) {
            res = [];
          }

          return res;
        }),
        _rating: (product as any).reviewRatingStatistics,
        _slugAllLocales: current.slugAllLocales,
        vatPercentage: getVatPercentage(product, countryCode)
      }));
    })
    .reduce((prev, curr) => [...prev, ...curr], []);
};

export default extendProducts;
