
import { EVENT_PAGE } from '~/constants/googleTagManager';
import { useIsPage, useAlgolia } from '~/composables';
import { PageViewPageCategory } from '~/types/integrations/tagManager/TagManagerIntegration';

export default function (): { getCurrentPageType: Function } {
  const { isOnProductPage, isOnHomePage, isCheckoutPage, isOnSearchPage } = useIsPage();
  const { isOnCategory } = useAlgolia();

  const getCurrentPageType = (): PageViewPageCategory => {
    let pageType: PageViewPageCategory;
    const isCategory = isOnCategory();
    if (isOnSearchPage.value) {
      pageType = EVENT_PAGE.SEARCH;
    } else if (isOnHomePage.value) {
      pageType = EVENT_PAGE.HOMEPAGE;
    } else if (isOnProductPage.value) {
      pageType = EVENT_PAGE.PDP;
    } else if (isCheckoutPage.value) {
      pageType = EVENT_PAGE.CHECKOUT;
    } else if (isCategory) {
      pageType = EVENT_PAGE.CATEGORY;
    }
    return pageType;
  };

  return { getCurrentPageType };
};
