import type { ComputedRef } from '@nuxtjs/composition-api';
import { useStore, computed } from '@nuxtjs/composition-api';
import { RootState } from '~/types/vuex/Vuex';

type GetterReturnType<T, K extends keyof T> = T[K] extends (...args: any[]) => infer R ? R : undefined;
type MutationPayload<T> = T extends (state: any, payload: infer P) => any ? P : undefined;
type ActionPayload<T> = T extends (context: any, payload: infer P) => any ? P : undefined;

export const defineModule = <T extends keyof RootState, M={}, A={}, G={}> (moduleName:T) => {
  const store = useStore<RootState>();

  const state = store.state[moduleName];

  const moduleGetters = {} as Record<keyof G, ComputedRef<GetterReturnType<G, keyof G>>>;
  if (store.state[moduleName].getterKeys) {
    store.state[moduleName].getterKeys.forEach((getter) => {
      moduleGetters[getter] = computed(() => store.getters[`${moduleName}/${getter}`]);
    });
  }

  const moduleMutations = {} as { [K in keyof M]: (payload?: MutationPayload<M[K]>) => void };
  if (store.state[moduleName].mutationKeys) {
    store.state[moduleName].mutationKeys.forEach((mutation) => {
      moduleMutations[mutation] = (payload) => {
        store.commit(`${moduleName}/${mutation}`, payload);
      };
    });
  }

  const moduleActions = {} as { [K in keyof A]: (payload?: ActionPayload<A[K]>) => void };
  if (store.state[moduleName].actionKeys) {
    store.state[moduleName].actionKeys.forEach((action) => {
      moduleActions[action] = (payload) => {
        store.dispatch(`${moduleName}/${action}`, payload);
      };
    });
  }
  // TODO - Destructure mutations, actions and getters https://expondo.atlassian.net/browse/INSP-3981
  return {
    state,
    getters: moduleGetters,
    mutations: moduleMutations,
    actions: moduleActions
  };
};
