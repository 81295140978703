import { ProductExtraGuaranteeState } from '~/types/vuex/ProductExtraGuarantee';
import { NavigationState } from '~/types/vuex/Navigation';
import { ClientIPState } from '~/types/vuex/ClientIP';
import { ClientEmailState } from '~/types/vuex/ClientEmail';
import { AnchorNavState } from '~/types/vuex/AnchorNav';

export const MODULE_NAMES = {
  EXTRA_GUARANTEE_MODULE_NAME: 'productExtraGuarantee',
  NAVIGATION_MODULE_NAME: 'navigation',
  CLIENT_IP_MODULE_NAME: 'clientIP',
  CLIENT_EMAIL_MODULE_NAME: 'clientEmail',
  ANCHOR_NAV_MODULE_NAME: 'anchorNav'
} as const;

export type RootState = {
  [MODULE_NAMES.EXTRA_GUARANTEE_MODULE_NAME]: ProductExtraGuaranteeState
  [MODULE_NAMES.NAVIGATION_MODULE_NAME]: NavigationState,
  [MODULE_NAMES.CLIENT_IP_MODULE_NAME]: ClientIPState,
  [MODULE_NAMES.CLIENT_EMAIL_MODULE_NAME]: ClientEmailState,
  [MODULE_NAMES.ANCHOR_NAV_MODULE_NAME]: AnchorNavState
};

export interface ExtendedState {
  getterKeys?: string[],
  mutationKeys?: string[],
  actionKeys?: string[]
}
