import { Logger, sharedRef, useVSFContext } from '@vue-storefront/core';
import type { CustomQuery } from '@vue-storefront/core';
import type {
  Address,
  Payment,
  CartUpdateAction,
  ProductVariant,
  LineItem,
  Cart,
  Customer
} from '@vsf-enterprise/commercetools-types';
import { useCart, useUser, userGetters } from '@vsf-enterprise/commercetools';
import type { Ref } from '@nuxtjs/composition-api';
import { computed, reactive, ref } from '@nuxtjs/composition-api';
import {
  setCustomerEmail,
  setShippingAddressAction,
  setBillingAddressAction,
  addPayment,
  addExtraGuaranteeLineItem
} from '~/helpers/cart/cartActions';
import { CUSTOM_QUERIES } from '~/constants/customQueries';
import {
  useI18n,
  useIntegrations,
  useStock
} from '~/composables';
import {
  setVatNumber,
  VatNumber
} from '~/helpers/cart/customFields';
import { CartUpdateAction as CiaUpdateAction } from '~/types/integrations/cia/event/cart-update/CartUpdateAction';
import { orderWithoutEmailId } from '~/constants/log';
import { VatResult } from '~/types/integrations/vatValidation/VatIntegration';
import callFunctionWithRetries from '~/helpers/retry/callFunctionWithRetries';
import { getStoreKey } from '~/helpers/cart/getStoreKey';
import { asyncDebounce } from '~/helpers/lodash/asyncDebounce';
import { usePromiseQueue } from '~/composables/modules/usePromiseQueue';
import { DEBOUNCE_TIMES } from '~/constants/debounceTimes';

export const ERROR_NOT_FOUND_IN_STORE = 'was not found in store';

const customError = reactive(
  { updateCustomCart: '' }
);

export default function () {
  const {
    cart,
    setCart: oldSetCart,
    load,
    addItem,
    removeItem,
    updateItemQty,
    clear,
    applyCoupon,
    removeCoupon,
    loading,
    error: oldError
  } = useCart();

  const {
    loadStock
  } = useStock('cart');

  const isLoaded = ref(false);

  const setCart = oldSetCart as (cart: Cart | null) => void;

  const { countryLocalization } = useI18n();
  const { $ct: { api } } = useVSFContext();
  const { $cia } = useIntegrations();

  const reloadCart = async () => {
    setCart(null);
    await newLoad();
  };

  const error = computed(() => ({
    ...oldError.value,
    ...customError
  }));

  const { user } = useUser();
  // TODO - revert syntax once fix is pushed for type incoherence between composables and getters
  // https://expondo.atlassian.net/browse/INSP-988
  const userEmailAddress = computed(() => userGetters.getEmailAddress(user.value as Customer));
  const emailAddress = computed(() => cart.value?.customerEmail);
  const updateCustomerEmailFromUser = async () => {
    await updateCustomerEmail(userEmailAddress.value);
  };

  const handleCartUpdate = (action: CiaUpdateAction, sku: string) => {
    try {
      $cia.event.cartUpdate(action, sku);
    } catch (error) {
      Logger.error(`cia|cartUpdate error: ${error}`);
    }
  };

  const persistCartId = (cartId?: string) => {
    if (cartId) {
      try {
        $cia.mutations.setCartId(cartId);
      } catch (error) {
        Logger.error(`cia|setCartId error: ${error}`);
      }
    }
  };

  async function updateCustomerEmail(email: Maybe<string>) {
    if (!email) {
      return;
    }
    if (!cart.value) {
      await newLoad();
    }
    if (emailAddress.value === email) {
      return;
    }
    if (cart.value) {
      const actions: CartUpdateAction[] = [setCustomerEmail(email)];
      await updateCustomCart(actions);
      try {
        $cia.mutations.setEmail(email);
      } catch (error) {
        Logger.error(`cia|setEmail error: ${error}`);
      }
    } else {
      Logger.info(`${orderWithoutEmailId} cart setEmail wasn't able to set because of cart not existed`);
    }
  }

  async function updatePayment(id: Payment['id']) {
    if (cart.value) {
      const actions: CartUpdateAction[] = [addPayment({ id })];
      await updateCustomCart(actions);
    }
  }

  async function addExtraGuaranteeProduct(parentSku: string) {
    const action: CartUpdateAction = addExtraGuaranteeLineItem(parentSku);
    await updateCustomCart([action]);

    handleCartUpdate(CiaUpdateAction.Add, parentSku);
  }

  const setUpdateCustomCartError = (value: string) => {
    customError.updateCustomCart = value;
  };
  const _updateCustomCartWithActions = async () => {
    await updateCustomCartFunction(actionsSaved.value);
    actionsSaved.value = [];
  };

  const cartPromiseQueueKey = 'cartPromiseQueue';
  const promiseQueue = usePromiseQueue(_updateCustomCartWithActions, cartPromiseQueueKey);
  const actionsSaved = sharedRef<CartUpdateAction[]>([], 'actionsCart');
  async function updateCustomCart(actions: CartUpdateAction[]) {
    setUpdateCustomCartError('');
    actionsSaved.value.push(...actions);
    const { error } = await callFunctionWithRetries(() => promiseQueue.execute());
    setUpdateCustomCartError(error);
  }
  const updateCustomCartFunction = asyncDebounce(async (actions: CartUpdateAction[]) => {
    if (!actions.length) {
      return;
    }
    const cartResponse = await api.updateCart({
      id: cart.value.id,
      version: cart.value.version,
      actions
    }, CUSTOM_QUERIES.UPDATE_CART_CUSTOM);
    const dataCart = cartResponse?.data?.cart;
    if (dataCart) {
      setCart(dataCart);
      return;
    }
    const message = cartResponse?.message;
    if (!message) {
      return;
    }
    Logger.error('UpdateCustomCart error, message: ' + message + ' actions: ' + JSON.stringify(actions));
    await handleErrorMessage(message);
    setUpdateCustomCartError(message);
  }, DEBOUNCE_TIMES.CART_API);

  async function setShippingDetails(address: Partial<Address>) {
    if (cart.value) {
      let actions: CartUpdateAction[] = [];
      actions = [
        setShippingAddressAction(address)
      ];
      await updateCustomCart(actions);
    }
  }

  async function setBillingDetails(
    address: Partial<Address>,
    vatNumber: VatNumber,
    vatResult: VatResult | null = null
  ) {
    if (!cart.value) {
      return;
    }

    const actions: CartUpdateAction[] = [
      setBillingAddressAction(address)
    ];
    if (vatNumber) {
      actions.push(setVatNumber('Billing', vatNumber, vatResult));
    }

    await updateCustomCart(actions);
  }

  async function newLoad() {
    await load();
    isLoaded.value = true;
    if (!cart.value) {
      return;
    }
    await clearIfOtherStoreCart();
    if (!cart.value) {
      return;
    }
    persistCartId(cart.value.id);
    if (cart.value.lineItems) {
      loadStock({ items: cart.value.lineItems });
    }
  }

  async function clearIfOtherStoreCart() {
    const storeKey = getStoreKey(cart.value);
    if (storeKey && (storeKey !== countryLocalization?.value.storeId)) {
      await newClear();
    }
  }

  async function newAddItem(params: { product: ProductVariant, quantity: number, customQuery?: CustomQuery }) {
    await newLoad();
    const lineItem = cart.value?.lineItems?.find((item: LineItem) => item.variant?.sku === params.product.sku);
    if (lineItem) {
      const newQuantity = lineItem.quantity + params.quantity;
      await newUpdateItemQty({
        product: lineItem,
        quantity: newQuantity,
        customQuery: params.customQuery
      }, CiaUpdateAction.Add);
      return;
    }
    await updateCustomerEmailFromUser();
    await addItem(params);
    const message = error.value.addItem?.message;
    if (!message) {
      handleCartUpdate(CiaUpdateAction.Add, params.product.sku);
      cart?.value?.lineItems && loadStock({ items: cart.value.lineItems, cartId: cart.value.id });
      return;
    }
    await handleErrorMessage(message);
  }

  async function newRemoveItem(params: { product: LineItem, customQuery?: CustomQuery }) {
    if (cart.value) { // TODO - validate if this if makes sense for this check  INSP-776
      persistCartId(cart.value.id);
      await removeItem(params);
      handleCartUpdate(CiaUpdateAction.Remove, params.product.variant.sku);
    }
  }

  async function newUpdateItemQty(params: {
    product: LineItem,
    quantity?: number,
    customQuery?: CustomQuery
  }, ciaActionType = CiaUpdateAction.Remove) {
    if (!cart.value) {
      return;
    } // TODO - validate if this if makes sense for this check  INSP-776
    await updateItemQty(params);
    const message = error.value.updateItemQty?.message;
    if (!message) {
      handleCartUpdate(ciaActionType, params.product.variant.sku);
      return;
    }
    await handleErrorMessage(message);
  }

  async function handleErrorMessage(message: string) {
    if (message?.includes(ERROR_NOT_FOUND_IN_STORE)) {
      await newClear();
      return;
    }
    await reloadCart();
    throw new Error(message);
  }

  async function newClear() {
    if (cart.value) { // TODO - validate if this if makes sense for this check  INSP-776
      persistCartId('');
      await clear();
      setCart(null);
    }
  }

  async function newApplyCoupon(params: { couponCode: string, customQuery?: CustomQuery }) {
    if (cart.value) { // TODO - validate if this if makes sense for this check  INSP-776
      persistCartId(cart.value.id);
      await applyCoupon(params);
    }
  }

  async function newRemoveCoupon(params: { couponCode: string, customQuery?: CustomQuery }) {
    if (cart.value) { // TODO - validate if this if makes sense for this check  INSP-776
      persistCartId(cart.value.id);
      await removeCoupon(params);
    }
  }

  async function updateQuantity(product: LineItem, quantity: number) {
    await updateQuantityWithoutRetry(product, quantity);
    if (error.value.updateItemQty?.message) {
      await newClear();
      await updateQuantityWithoutRetry(product, quantity);
    }
  }

  async function updateQuantityWithoutRetry(product: LineItem, quantity: number) {
    await updateItemQty({
      product,
      quantity,
      customQuery: CUSTOM_QUERIES.UPDATE_CART_CUSTOM
    });
  }

  const taxMode = computed(() => cart?.value?.taxMode);

  const calculatedCodFee = computed(() =>
    cart?.value?.custom?.customFieldsRaw?.find(field => field.name === 'calculatedCodFee')?.value);

  const codFeeAmount = computed(() =>
    calculatedCodFee.value ? calculatedCodFee.value.centAmount / (10 ** calculatedCodFee.value.fractionDigits) : 0);

  return {
    // TODO - revert syntax once fix is pushed for type incoherence between composables and getters -
    // https://expondo.atlassian.net/browse/INSP-988
    cart: cart as Ref<Cart>,
    codFeeAmount,
    loading,
    isLoaded: computed(() => (isLoaded.value || !!cart.value)),
    error,
    setCart,
    updateCustomerEmail,
    updatePayment,
    setShippingDetails,
    setBillingDetails,
    updateCustomCart,
    load: newLoad,
    clearIfOtherStoreCart,
    addItem: usePromiseQueue(newAddItem, cartPromiseQueueKey).execute,
    removeItem: usePromiseQueue(newRemoveItem, cartPromiseQueueKey).execute,
    updateItemQty: usePromiseQueue(newUpdateItemQty, cartPromiseQueueKey).execute,
    clear: usePromiseQueue(newClear, cartPromiseQueueKey).execute,
    applyCoupon: usePromiseQueue(newApplyCoupon, cartPromiseQueueKey).execute,
    removeCoupon: usePromiseQueue(newRemoveCoupon, cartPromiseQueueKey).execute,
    reloadCart,
    setUpdateCustomCartError,
    updateQuantity: usePromiseQueue(updateQuantity, cartPromiseQueueKey).execute,
    taxMode,
    addExtraGuaranteeProduct
  };
}
