import { extendModule } from '~/helpers/vuex/extendModule/extendModule';
import type { ClientEmailState } from '~/types/vuex/ClientEmail';

const state = () => ({
  clientEmail: ''
});

const mutations = {
  setClientEmail(state: ClientEmailState, payload: string) {
    state.clientEmail = payload;
  }
};

export default extendModule({
  state,
  mutations
});
