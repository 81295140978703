import {
  ADD_TO_CART_EVENT_NAME,
  ADD_TO_CART_EVENT_DETAIL,
  ADD_TO_CART_DEFAULT_EVENT_TYPE,
  PRIMARY_CATEGORY
} from '~/constants/googleTagManager';

import { TagProduct } from '~/types/integrations/tagManager/TagProduct';

export class AddToCartTags {
  event = ADD_TO_CART_EVENT_NAME
  eventInfo = { detail: ADD_TO_CART_EVENT_DETAIL, text_displayed: '', triggered_in: '' };

  /* eslint eslint-comments/no-use: off */
  // eslint-disable-next-line camelcase
  public category: { event_type: string, primary_category: string };
  public ecommerce: { items?: Array<TagProduct> }

  constructor(productTags: TagProduct, textDisplayed: string = '', triggeredIn: string = '') {
    this.category = {
      event_type: ADD_TO_CART_DEFAULT_EVENT_TYPE,
      primary_category: PRIMARY_CATEGORY
    };
    this.eventInfo.text_displayed = textDisplayed;
    this.eventInfo.triggered_in = triggeredIn;
    this.ecommerce = { items: [productTags] };
  }
}
