import {
  useAdyen
} from '@vsf-enterprise/adyen-commercetools';
import {
  useI18n,
  useCheckoutAddresses,
  usePayNowEvent
} from '~/composables';
import { GooglePayResponse } from '~/types/GooglePayResponse';
import { AddressType as Address } from '~/types/AddressType';
import type { AddressType } from '~/types/AddressType';
import { PAYMENT_METHODS } from '~/constants/paymentMethods';

function buildGooglePayAddress(stateData: GooglePayResponse, addressType: AddressType) {
  const address = addressType === Address.Shipping
    ? stateData.shippingAddress
    : stateData.paymentMethodData.info.billingAddress;

  return {
    firstName: address.name,
    // the firtName includes the whole name but lastName is needed to be empty instead of undefined
    lastName: '',
    phone: address.phoneNumber,
    country: address.countryCode,
    city: address.locality,
    postalCode: address.postalCode,
    streetName: address.address1,
    // the streetName includes the whole address but streetNumber is needed to be empty instead of undefined
    streetNumber: ''
  };
}

type DropinConfiguration = ReturnType<ReturnType<typeof useAdyen>['buildDropinConfiguration']>

export default function () {
  const { countryCode } = useI18n();
  const { setAddressesDetails } = useCheckoutAddresses();
  const { callPayNowEvent } = usePayNowEvent();
  // to be updated when a new express payment method is added
  const expressPaymentsOptionsSize = 1;

  function configureExpressCheckout(dropinConfiguration: DropinConfiguration) {
    dropinConfiguration.paymentMethodsConfiguration.googlepay = {
    // TRAN-1761 - email, billing/shipping details to be set to commercetools
      onAuthorized: async (stateData: GooglePayResponse) => {
        await setGooglePayCustomerDetails(stateData);
      },
      buttonColor: 'black',
      buttonType: 'plain',
      buttonSizeMode: 'fill',
      shippingAddressRequired: true,
      emailRequired: true,
      shippingAddressParameters: {
        allowedCountryCodes: [countryCode.value.toUpperCase()],
        phoneNumberRequired: true
      },
      billingAddressRequired: true,
      billingAddressParameters: {
        format: 'FULL',
        phoneNumberRequired: true
      }
    };
  };

  function onClickHandler(resolve: () => void) {
    callPayNowEvent(PAYMENT_METHODS.GOOGLEPAY);
    resolve();
  }

  function configureGooglePayOnClick(dropinConfiguration: DropinConfiguration) {
    if (dropinConfiguration.paymentMethodsConfiguration?.googlepay) {
      dropinConfiguration.paymentMethodsConfiguration.googlepay.onClick = onClickHandler;
      return;
    }

    dropinConfiguration.paymentMethodsConfiguration.googlepay = {
      onClick: onClickHandler
    };
  }

  async function setGooglePayCustomerDetails(stateData: GooglePayResponse) {
    await setAddressesDetails(buildGooglePayAddress(stateData, Address.Shipping),
      buildGooglePayAddress(stateData, Address.Billing), stateData.email);
  }

  return {
    configureExpressCheckout,
    expressPaymentsOptionsSize,
    configureGooglePayOnClick
  };
}
