


























import { SfButton } from '@storefront-ui/vue';
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { cartGetters } from '@vsf-enterprise/commercetools';
import { useCartExtended } from '@/composables';

export default defineComponent({
  name: 'CartIcon',
  components: {
    SfButton
  },
  props: {
    isWhite: {
      type: Boolean,
      default: true
    },
    isCartWithDot: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { cart } = useCartExtended();
    const cartTotalItems = computed(() => {
      const count = cartGetters.getTotalItems(cart.value);
      return count ? count.toString() : null;
    });

    const iconWithDotClasses = `cart-badge--with-dot text-EXPONDO-white w-4 h-4 rounded-full 
    bg-EXPONDO-red text-sm flex items-center justify-center`;

    return {
      cartTotalItems,
      iconWithDotClasses
    };
  }
});
