import { ssrRef } from '@nuxtjs/composition-api';
import { Logger, useVSFContext } from '@vue-storefront/core';
import { CART_DISCOUNT } from '~/constants/cartDiscounts';

export default () => {
  const { $ct } = useVSFContext();

  const isActive = ssrRef<boolean>(false, 'isVolumeDiscountActive');

  const load = async () => {
    try {
      const { isActive: isMultibuyActive } = await $ct.api.getVolumeDiscounts([
        CART_DISCOUNT.MULTIBUY3,
        CART_DISCOUNT.MULTIBUY5,
        CART_DISCOUNT.MULTIBUY10
      ]);
      isActive.value = isMultibuyActive;
    } catch (err) {
      Logger.error('error.isVolumeDiscountActive', String(err));
    }
  };

  return {
    isActive,
    load
  };
};
