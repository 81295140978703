import { MAX_NUMBER_OF_RETRIES } from '~/constants/numbers';
import { errorToString } from '~/helpers/error/errorToString';

export default async (callback: () => unknown) => {
  let error = '';
  let data: ReturnType<typeof callback>;
  for (let i = 0; i <= MAX_NUMBER_OF_RETRIES; i++) {
    try {
      error = '';
      data = await callback();
      break;
    } catch (e: unknown) {
      error = errorToString(e);
    }
  }
  return { error, data };
};
