
















import { defineComponent, computed, defineAsyncComponent, onMounted } from '@nuxtjs/composition-api';
import { useUser } from '@vsf-enterprise/commercetools';
import { ref } from '@vue/composition-api';
import NoFooterLayout from './no-footer-layout.vue';
import DefaultFooter from '~/components/organisms/Footer/DefaultFooter.vue';
import { useIsPage } from '~/composables';

export default defineComponent({
  name: 'Default',
  components: {
    NoFooterLayout,
    Newsletter: defineAsyncComponent(() => import(/* webpackChunkName: "Newsletter" */
      '~/components/molecules/Newsletter/Newsletter.vue')),
    DefaultFooter
  },
  props: {
    fullWidth: Boolean
  },
  setup() {
    const { isAuthenticated } = useUser();
    const showNewsletter = computed(() => !isAuthenticated.value);
    const isMounted = ref(false);
    const { isOnProductPage } = useIsPage();
    const paddingForStickyAddToCart = 'pb-22 lg:pb-0';

    onMounted(() => {
      isMounted.value = true;
    });

    return {
      isMounted,
      showNewsletter,
      isOnProductPage,
      paddingForStickyAddToCart
    };
  }
});
