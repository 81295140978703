import type { ProductsSearchParams } from '@vsf-enterprise/commercetools';
import { AttributeType } from '@vsf-enterprise/commercetools-api';
import type { ProductsSearchFilter, ProductsSearchParamsExtended } from '~/types/product/ProductSearch';
import { ELIGIBLE_FOR_SELLING_CUSTOM_FIELD, RELATED_PRODUCT_LIMIT } from '~/constants/commerceTools';
import { StringBoolean } from '~/types/StringBoolean';
import { excludeUnsellableProduct } from '~/helpers/commercetools/graphql/excludeUnsellableProduct';
import type { ProductsSearchLanguageParams } from '~/types/product/ProductsSearchLanguageParams';

export const getProductSearchParams = (
  searchParams: ProductsSearchParams,
  languageParams: ProductsSearchLanguageParams
): ProductsSearchParamsExtended => {
  const storeFilter: ProductsSearchFilter = {
    type: AttributeType.LOCALIZED_STRING,
    name: ELIGIBLE_FOR_SELLING_CUSTOM_FIELD,
    value: StringBoolean.true
  };
  let customFiltersForSearch = excludeUnsellableProduct(languageParams.languageAndCountry);
  if (searchParams?.customFilters) {
    customFiltersForSearch += searchParams.customFilters;
  }

  const searchParamsWithFilter: ProductsSearchParamsExtended = {
    ...searchParams,
    filters: [storeFilter],
    limit: RELATED_PRODUCT_LIMIT,
    customFilters: customFiltersForSearch
  };
  if (languageParams.languagePrefix) {
    searchParamsWithFilter.languagePrefix = languageParams.languagePrefix;
  }

  return searchParamsWithFilter;
};
