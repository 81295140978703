import { computed } from '@nuxtjs/composition-api';
import { addToCartSimplifiedObject } from '~/helpers/cart/cartActions';
import {
  useAddToCart,
  useExtraGuarantee,
  useRouteOfAddToCart,
  useAddToCartTagManager
} from '~/composables';
import { isTestProduct } from '~/helpers/product/isTestProduct';

type useAddToCartQuantityParams = {
  sku: string;
  slug: string;
  productPrice: number;
  isMainProduct: boolean;
  addToCartText: string;
  isWarrantySection: boolean;
};

type AddToCartParams = {
  isWithExtraGuarantee?: boolean;
}

type AddToCartFunction = (quantity: number, params?: AddToCartParams) => void

type useAddToCartQuantityReturn = {
  addQuantity: AddToCartFunction;
};

export const useAddToCartQuantity = ({
  sku,
  slug,
  productPrice,
  isMainProduct,
  isWarrantySection,
  addToCartText
}: useAddToCartQuantityParams): useAddToCartQuantityReturn => {
  const { setExtraGuaranteeInfo } = useExtraGuarantee();
  const { addToCart } = useAddToCart(slug, sku);
  const { getAddToCartButtonLocation } = useRouteOfAddToCart();
  const { triggerTags } = useAddToCartTagManager({
    slug,
    addToCartText
  });
  const testProduct = computed(() => isTestProduct(sku));
  const addQuantity: AddToCartFunction = (quantity, { isWithExtraGuarantee } = {}) => {
    if (testProduct.value) {
      return;
    }
    setExtraGuaranteeInfo(productPrice, sku);
    addToCart(addToCartSimplifiedObject(sku), quantity, isWithExtraGuarantee);
    const triggeredIn = getAddToCartButtonLocation({
      isMainProduct,
      isWarrantySection
    });
    triggerTags({
      quantity,
      triggeredIn
    });
  };

  return {
    addQuantity
  };
};
