import qs from 'qs';
import type VueRouter from 'vue-router';
import removeIndexNameFromQuery from './removeIndexNameFromQuery';
import { QUERY_PARAMETER_JOINER } from '~/constants/routes';
import { ALGOLIA_ROOT_LEVEL_QUERY_PARAMETERS } from '~/constants/algolia';

export default (
  vueRouter: VueRouter, routeState: Record<string, any>, isSSRMode: boolean, algoliaIndexName: string): string => {
  const nonAlgoliaQueryParameters: Record<string, string | (string | null)[] | undefined> = {};
  let someParameterIsActive = false;

  const algoliaIndex = Object.keys(routeState)?.[0];
  const queryParameters = vueRouter.currentRoute.query;

  Object.entries(queryParameters).forEach(([queryLabel, queryValue]) => {
    if (queryValue) {
      someParameterIsActive = true;
    }
    if (!ALGOLIA_ROOT_LEVEL_QUERY_PARAMETERS.includes(queryLabel)) {
      nonAlgoliaQueryParameters[queryLabel] = queryValue;
    }
  });

  routeState = removeIndexNameFromQuery(routeState, algoliaIndexName);

  const algoliaQueries = qs.stringify(routeState, {
    addQueryPrefix: true
  });
  const nonAlgoliaQueries = qs.stringify(nonAlgoliaQueryParameters, {
    addQueryPrefix: false
  });
  return isSSRMode && someParameterIsActive && algoliaIndex && algoliaQueries
    ? [algoliaQueries, nonAlgoliaQueries].join(QUERY_PARAMETER_JOINER)
    : algoliaQueries;
};
