export const COMPONENTS = {
  TRUSTED_PAYMENTS: {
    SLUG: 'trusted-payments'
  },
  APP_FOOTER: {
    SLUG: 'app-footer'
  },
  PHONE_NUMBER: {
    SLUG: 'phone-number'
  },
  CREATE_ACCOUNT_PROMO: {
    SLUG: 'create-account-promo'
  },
  CREATE_ACCOUNT_INFO: {
    SLUG: 'create-account-info'
  },
  NOTIFICATION_BANNER: {
    SLUG: 'notification-banner'
  },
  PROMO_BANNERS: {
    SLUG: 'promo-banners'
  },
  SHOP_BY_DEPARTMENT: {
    SLUG: 'shop-by-department'
  },
  TRUSTED_BRANDS: {
    SLUG: 'trusted-brands'
  }
};

export const PAGE_DELIVERY_KEYS = {
  HOME: {
    SLUG: 'home'
  },
  LANDER: {
    SLUG: 'lander'
  }
};

const INTERNAL_CMS_BASE_PATH = '/cms-schemas/';
export const INTERNAL_CMS_COMPONENTS_PATH = `${INTERNAL_CMS_BASE_PATH}components/`;
export const INTERNAL_CMS_PAGES_PATH = `${INTERNAL_CMS_BASE_PATH}pages/published/`;
