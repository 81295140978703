import {
  useIntegrations,
  useProductForTagManager
} from '~/composables';

type useAddToCartTagManagerParams = {
  slug: string;
  addToCartText: string;
};

type triggerTagsParams = {
  quantity: number;
  triggeredIn: string;
};

type useAddToCartTagManagerReturn = {
  triggerTags: (params: triggerTagsParams) => void;
};

export const useAddToCartTagManager = ({
  slug,
  addToCartText
}: useAddToCartTagManagerParams): useAddToCartTagManagerReturn => {
  const { $tagManager } = useIntegrations();
  const { masterProduct: product, search: searchProduct } = useProductForTagManager();
  const triggerTags = ({
    quantity, triggeredIn
  }: triggerTagsParams) => {
    const searchParams = { slug };
    searchProduct(searchParams).then(() => {
      if (product.value) {
        $tagManager.events.triggerAddToCartTags(product.value, quantity, addToCartText, triggeredIn);
      }
    });
  };

  return {
    triggerTags
  };
};
