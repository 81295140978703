import { computed } from '@nuxtjs/composition-api';
import type { Ref, ComputedRef } from '@nuxtjs/composition-api';
import { useIsPage } from '~/composables';
import { AbsoluteUrlPage } from '~/types/components/AbsoluteUrl/AbsoluteUrlPage';
import type { DynamicPage, DynamicPageContent } from '~/types/cms/DynamicPageContent';

const useAbsoluteUrlPage = (
  content: Ref<DynamicPageContent>
) => {
  const { isOnProductPage, isOnInternalCmsPage } = useIsPage();

  const dynamicPage: ComputedRef<DynamicPage | undefined> =
    computed(() => content?.value?.[0]);

  const cmsSideMenu = computed(() =>
    dynamicPage.value && dynamicPage.value.menu
  );
  const isInsideContainer = computed(() =>
    dynamicPage.value && dynamicPage.value.container
  );
  const cmsPageContent = computed(() =>
    dynamicPage.value && dynamicPage.value.content
  );

  const seoContent = computed(() =>
    dynamicPage.value && dynamicPage.value.seo
  );

  const isValidCategoryCMSPage = computed(() =>
    dynamicPage.value && dynamicPage.value.isCategoryPage
  );
  /**
   * currentPage determination logic can instantly determine if we are on PDP from URL.
   * If we are not on PDP, we check if we are on valid CMS page instantly
   * If not,we are on the last possibility: either Category Search page or an incorrect URL.
   * Incorrect URL case is handled inside the SearchCategory page by checking validity of the category slug.
   */
  const currentPage = computed(() => {
    if (isOnProductPage.value) {
      return AbsoluteUrlPage.pdp;
    } else if (isOnInternalCmsPage.value) {
      return AbsoluteUrlPage.internalCms;
    } else {
      return AbsoluteUrlPage.categorySearch;
    }
  });

  return {
    isValidCategoryCMSPage,
    currentPage,
    cmsSideMenu,
    cmsPageContent,
    isInsideContainer,
    seoContent
  };
};

export default useAbsoluteUrlPage;
