
import type { HitResultItem } from 'vue-instantsearch';
import {
  useIntegrations,
  usePageEventLocation,
  useCategories
} from '~/composables';
import { TriggerViewItemListParameters } from '~/types/integrations/tagManager/TagManagerIntegration';
import { EVENT_PAGE } from '~/constants/googleTagManager';

export default function (
  eventListItems: Maybe<HitResultItem[]>,
  additionalParameters: {
    searchTerm: Maybe<string>,
    currentSlug: Maybe<string>
  }
): { callViewItemListEvent: Function } {
  const { $tagManager } = useIntegrations();
  const { getCurrentPageType } = usePageEventLocation();
  const {
    getters: {
      currentCategoryName
    }
  } = useCategories();
  const getViewItemListEventData = (): TriggerViewItemListParameters => {
    const pageType = getCurrentPageType();
    const categoryName = currentCategoryName.value || '';
    const searchKey = additionalParameters.searchTerm || '';

    const eventListIdGtm = pageType === EVENT_PAGE.CATEGORY ? additionalParameters.currentSlug : pageType;
    const eventListName = pageType === EVENT_PAGE.CATEGORY ? categoryName : searchKey;

    const viewItemListEventData: TriggerViewItemListParameters = {
      id: eventListIdGtm,
      name: eventListName,
      items: eventListItems || []
    };
    return viewItemListEventData;
  };

  const callViewItemListEvent = () => {
    const viewItemListEventData = getViewItemListEventData();
    $tagManager.events.triggerViewItemList(viewItemListEventData);
  };

  return { callViewItemListEvent };
}
