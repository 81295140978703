














import { defineComponent } from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';
import { useNavigationState } from '~/composables';
import { i18nToAlgoliaMainIndex } from '~/helpers/locales/i18nCurrentDetails';
import { createOnSaleLink } from '~/helpers/navigation/createNavLinks';

export default defineComponent({
  name: 'NavMainBarOnSale',
  setup() {
    const {
      toggleNavigation,
      isNavigationOpen
    } = useNavigationState();

    const { i18n } = useVSFContext();

    const algoliaSearchIndex = i18nToAlgoliaMainIndex(i18n);
    const onSaleLink = createOnSaleLink(algoliaSearchIndex);

    return {
      toggleNavigation,
      isNavigationOpen,
      onSaleLink
    };
  }
});
