












import { defineComponent } from '@nuxtjs/composition-api';
import { extractComponents } from '~/helpers/cms/extractComponents';
import { ExtractedComponent } from '~/types/cms/CmsComponentData';

export default defineComponent({
  name: 'RenderContent',
  components: {
    ImageBanner: () => import(/* webpackChunkName: "ImageBanner" */
      '~/cms/verticalLander/ImageBanner.vue'),
    HeroBanner: () => import(/* webpackChunkName: "HeroBanner" */
      '~/cms/HeroBannerCms.vue'),
    HomeProducts: () => import(/* webpackChunkName: "HomeProducts" */
      '~/cms/verticalLander/HomeProducts.vue'),
    StoryBanner: () =>
      import('~/cms/verticalLander/StoryBanner/StoryBanner.vue'),
    VerticalLanderCategory: () =>
      import('~/cms/verticalLander/VerticalLanderCategory.vue'),
    VerticalLanderCategories: () =>
      import('~/cms/verticalLander/VerticalLanderCategories.vue'),
    VerticalLanderLinkoutText: () =>
      import('~/cms/verticalLander/VerticalLanderLinkoutText.vue'),
    VerticalLanderLinkoutImage: () =>
      import(
        '~/cms/verticalLander/VerticalLanderLinkoutImage/VerticalLanderLinkoutImage.vue'
      ),
    VerticalLanderLinkout: () =>
      import('~/cms/verticalLander/VerticalLanderLinkout.vue'),
    HelpMenu: () => import(/* webpackChunkName: "HelpMenu" */
      '~/cms/help/HelpMenu.vue'),
    NavImageGrid: () => import(/* webpackChunkName: "NavImageGrid" */
      '~/cms/NavImageGridCms.vue'),
    DepartmentBlock: () => import(/* webpackChunkName: "DepartmentBlock" */
      '~/cms/DepartmentBlockCms.vue'),
    MarketingCardContainer: () =>
      import('~/cms/marketingCard/MarketingCardContainer.vue'),
    MarketingCard: () => import(/* webpackChunkName: "MarketingCard" */
      '~/cms/marketingCard/MarketingCard.vue'),
    MarketingCardWide: () => import(/* webpackChunkName: "MarketingCardWide" */
      '~/cms/marketingCard/MarketingCardWide.vue'),
    MarketingLandingPageConfig: () => import(/* webpackChunkName: "MarketingLandingPageConfig" */
      '~/cms/MarketingLandingPageConfig/MarketingLandingPageConfig.vue'),
    CategoryBoxesLinks: () => import(/* webpackChunkName: "CategoryBoxesLinks" */
      '~/cms/CategoryBoxesLinks/CategoryBoxesLinks.vue'),
    AppFooterWrapper: () => import(/* webpackChunkName: "AppFooterWrapper" */
      '~/cms/footer/AppFooterWrapper.vue'),
    FooterLinkColumn: () => import(/* webpackChunkName: "FooterLinkColumn" */
      '~/cms/footer/FooterLinkColumn.vue'),
    NotificationBannerCms: () => import(/* webpackChunkName: "NotificationBannerCms" */
      '~/cms/NotificationBannerCms/index.vue'),
    PromoBanners: () => import(/* webpackChunkName: "PromoBanners" */
      '~/cms/PromoBanners/PromoBanners.vue')
  },
  props: {
    content: {
      type: [Array, Object],
      default: () => {
        return [];
      }
    }
  },
  computed: {
    components(): ExtractedComponent[] {
      return typeof this.content !== 'string'
        ? extractComponents(this.content)
        : [];
    }
  }
});
