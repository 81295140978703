import { ssrRef } from '@nuxtjs/composition-api';
import { extendModule } from '~/helpers/vuex/extendModule/extendModule';
import type { ClientIPState } from '~/types/vuex/ClientIP';

const state = () => ({
  clientIP: ssrRef<string | null>(null)
});

const mutations = {
  setClientIP(state: ClientIPState, payload: string) {
    state.clientIP = payload;
  }
};

export default extendModule({
  state,
  mutations
});
