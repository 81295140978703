import { extendModule } from '~/helpers/vuex/extendModule/extendModule';

import { ProductExtraGuaranteeState } from '~/types/vuex/ProductExtraGuarantee';

const state = (): ProductExtraGuaranteeState => ({
  productExtraGuarantee: null
});
const mutations = {
  setProduct(state: ProductExtraGuaranteeState, payload: ProductExtraGuaranteeState['productExtraGuarantee']) {
    state.productExtraGuarantee = payload;
  }
};

export default extendModule({
  state,
  mutations
});
