







































import LazyHydrate from 'vue-lazy-hydration';
import { onSSR } from '@vue-storefront/core';
import { defineComponent, computed, useRoute } from '@nuxtjs/composition-api';
import BaseLayout from '~/layouts/base-layout.vue';
import AppHeader from '~/components/organisms/AppHeader.vue';
import TrustpilotHeader from '~/components/molecules/TrustPilot/TrustpilotHeader.vue';
import PromoBannerContainer from '~/components/organisms/Navigation/PromoBanner/PromoBannerContainer.vue';
import { COMPONENT_CACHING } from '~/constants/queryOptions';
import {
  useCategoryExtended, useNavigationState, useI18n, usePrefix
} from '~/composables';

export default defineComponent({
  name: 'NoFooterLayout',
  components: {
    BaseLayout,
    Navigation: () => import(/* webpackChunkName: "Navigation" */
      '~/components/organisms/Navigation/Navigation.vue'),
    PromoBannerContainer,
    LazyHydrate,
    AppHeader,
    TrustpilotHeader,
    CartSidebar: () => import(/* webpackChunkName: "CartSidebar" */
      '~/components/organisms/Cart/CartSidebar.vue'),
    ExtraGuaranteeSidebar: () => import(/* webpackChunkName: "ExtraGuaranteeSidebar" */
      '~/components/organisms/ExtraGuarantee/ExtraGuaranteeSidebar.vue'),
    // TODO - Reimplementation of WishlistSidebar https://expondo.atlassian.net/browse/INSP-2130
    // WishlistSidebar: () => import('~/components/organisms/WishlistSidebar.vue'),
    Notification: () => import(/* webpackChunkName: "Notification" */
      '~/components/molecules/Notification.vue')
  },
  props: {
    fullWidth: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const {
      isNavigationOpen
    } = useNavigationState();
    const { languagePrefix } = usePrefix();
    const route = useRoute();
    const componentCaching = computed(() => route.value?.query?.[COMPONENT_CACHING]);

    const {
      loadFirstLevelCategories,
      firstLevelCategoriesContainer
    } = useCategoryExtended();

    onSSR(async () => {
      await loadFirstLevelCategories(languagePrefix);
    });

    const { countryLocalization, languageAndCountry } = useI18n();
    const topLevelDomain = countryLocalization.value.topLevelDomain;

    return {
      isNavigationOpen,
      firstLevelCategoriesContainer,
      componentCaching,
      topLevelDomain,
      languageAndCountry
    };
  }
});

