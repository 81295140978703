export function createOnSaleLink (algoliaIndex:string) {
  return `/search?${algoliaIndex}%5BrefinementList%5D%5Btags%5D%5B0%5D=on-sale`;
}
const createCategoryLink = (algoliaIndex: string, categorySlug: string, tag: string) =>
  `/${categorySlug}?${algoliaIndex}${tag ? `%5BrefinementList%5D%5Btags%5D%5B0%5D=${tag}` : ''}`;
export function createCategoryOnSaleLink (algoliaIndex: string, categorySlug: string) {
  return createCategoryLink(algoliaIndex, categorySlug, 'on-sale');
}
export function createCategoryBestsellersLink (algoliaIndex: string, categorySlug: string) {
  return createCategoryLink(algoliaIndex, categorySlug, 'bestseller');
}
